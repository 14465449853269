<div
	class="modal fade"
	id="viewAllImages"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
>
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body p-2">
				<div class="fw-bold text-center row m-0">
					<div class="col-auto">
						<span
							(click)="emitters.addLogoEmiter.emit({ status: false })"
							data-bs-dismiss="modal"
							role="button"
							class="text-secondary fw-500"
						>
							{{ 'miscellaneous.buttons.back' | translate }}
						</span>
					</div>
					<div class="col text-center">
						<span class="fs-20">
							{{ 'modules.account.modals.view-all-images-modal.title' | translate }}
						</span>
					</div>
					<div class="col-auto">
						<span data-bs-dismiss="modal" role="button" class="text-secondary fw-500">
							{{ 'miscellaneous.buttons.exit' | translate }}
						</span>
					</div>
				</div>
				<div class="hr"></div>
				<div class="row">
					<div class="col-lg-3 mb-1" *ngFor="let image of images">
						<div class="view">
							<img
								[ngClass]="{ selected: image.selected }"
								(click)="chooseImage(image)"
								[src]="image.url"
								onerror="this.src='../../../../../assets/imgs/temp/timeline1.png'"
								alt="view"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button [disabled]="checkList()" (click)="save()" [disabled]="!selected" class="btn btn-primary">
					{{ 'miscellaneous.buttons.save' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>

<button #close data-bs-dismiss="modal" style="display: none"></button>
