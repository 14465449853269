<div
	class="modal fade"
	id="archives"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">{{ 'modals.archives_modal.archives' | translate }}</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body text-center">
				<div *ngFor="let archive of archives" (click)="openUrl(archive)">
					<i class="text-secondary bi bi-file-earmark-check"></i>
					<p>{{ archive.name }}.{{ archive.type }}</p>
				</div>
			</div>
			<div class="modal-footer">
				<button data-bs-dismiss="modal" class="btn btn-outline-primary">
					{{ 'modals.archives_modal.close' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
