import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface AlertData {
	title: string;
	subtitle?: string;
	confirmButtonText?: string;
	cancelButtonText?: string;
	show?: boolean;
	response?: string;
}

@Injectable({
	providedIn: 'root'
})
export class AlertInputService {
	data!: AlertData;
	public open = new Subject<AlertData>();

	// Instantiate and initialize the alert
	// @autor: Carlos Sánchez Mora
	// @date: 02-08-2022
	initiate(data: AlertData): any {
		if (!data.cancelButtonText) {
			data.cancelButtonText = 'Regresar';
		}
		this.data = { ...data, show: true };
		this.open.next(this.data);
	}

	// Close the alert
	// @autor: Carlos Sánchez Mora
	// @date: 02-08-2022
	hide(res: any): any {
		this.data = { ...this.data, show: false };
		this.open.next(res);
	}
}
