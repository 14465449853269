import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from './core/utils/branding.service';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'zeyo-frontend';

	constructor(public translate: TranslateService, private _brandingService: BrandingService) {
		const langs = ['en', 'es'];
		translate.addLangs(langs);
		translate.setDefaultLang('en');
		const lang = translate.getBrowserLang();
		for (const iterator of langs) {
			if (iterator === lang) {
				translate.use(iterator);
			}
		}
	}
}
