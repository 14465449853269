<div class="bg-lighter">
	<section class="p-3">
		<img class="logo" src="./assets/imgs/logos/principal.png" (click)="location.back()" />
		<section class="vertical-align page-fullscreen row m-0">
			<div class="col-lg-6 text-center mb-2">
				<img class="retry" src="./assets/svgs/retry.svg" alt="" />
			</div>
			<div class="col-lg-6">
				<h1 class="fw-500">¡Ups!</h1>
				<h5>No encontramos la página</h5>
				<p class="fw-500">404</p>
				<div>La buscamos por todos lados...</div>
				<div>¿Estas seguro que la dirección es la correcta?</div>
				<br />
				<button class="btn btn-primary" (click)="location.back()">Regresar</button>
			</div>
		</section>
	</section>
</div>
