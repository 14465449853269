import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LoadingService } from './loading.service';
import { EmittersService } from '../../../core/utils/emitters.service';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'closed',
				style({
					visibility: 'hidden',
					right: '-400px'
				})
			),
			state(
				'open',
				style({
					right: '40px'
				})
			),
			transition('open <=> closed', [animate('0.01s ease-in-out')])
		])
	]
})
export class LoadingComponent {
	// Loading subscription
	// @autor: Carlos Sánchez Mora
	// @date: 29-08-2022
	constructor(public loadingService: LoadingService, private _emittersService: EmittersService) {
		this.loadingService.open.subscribe(() => {});
	}

	// Close the toast
	// @autor: Carlos Sánchez Mora
	// @date: 29-07-2022
	close(): void {
		this._emittersService.loadingService.emit();
		this.loadingService.hide(true);
		return;
	}
}
