<div *ngIf="authenticationService.currentUser">
	<section class="content-agent" *ngIf="!settings.show" (click)="openChat()">
		<div class="btn-chat">
			<button class="btn btn-primary br-100">
				<div *ngIf="!loadings.search">
					<i *ngIf="settings.notification" class="bi bi-dot"></i>
					<i class="bi bi-chat-left-fill"></i>
					<div class="text-ai">IA</div>
				</div>
				<div *ngIf="loadings.search" class="dot btn-chat-dot"></div>
			</button>
		</div>
	</section>
	<section class="content-agent" *ngIf="settings.show">
		<!-- HEADER -->
		<div class="content-header p-agent">
			<div class="row">
				<div class="col">Asistente Zeyo IA</div>
				<div class="col-auto">
					<i role="button" (click)="settings.show = false" class="bi bi-x"></i>
				</div>
			</div>
		</div>
		<!-- END HEADER -->

		<!-- CONTENIDO DE LAS RESPUESTAS -->
		<div #contentResponse class="content-response p-1" *ngIf="chats.length > 0">
			<div
				[ngClass]="{
					'content-ia': chat.how === 'ia',
					'content-me': chat.how === 'me',
					'mb-1': idx < chats.length - 1
				}"
				*ngFor="let chat of chats; let idx = index"
			>
				<div class="vertical-align-left">
					<div>
						<div
							[ngClass]="{ 'bg-me': chat.how === 'me' }"
							class="content"
							[innerHTML]="chat.message"
						></div>
						<div class="content" *ngIf="chat.answer && chat.answer.url">
							<p>¿Deseas crear el proceso?</p>
							<button (click)="goTo(chat)" class="btn btn-primary">Si, crear el proceso</button>
						</div>
						<div class="content" *ngIf="chat.configurations.loading === 'loading'">
							<div class="dot"></div>
						</div>
						<div class="content text-muted" *ngIf="chat.configurations.loading === 'error'">
							<div>{{ chat.configurations.message }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- END CONTENIDO DE LAS RESPUESTAS -->

		<!-- CONTENIDO DE INGRESO DE DATOS -->
		<div class="content-input">
			<hr />
			<textarea
				[disabled]="settings.listening"
				#textarea
				[(ngModel)]="prompt"
				placeholder="Escribe aquí tu mensaje…"
				rows="1"
				(keydown.enter)="sendMessage()"
				(input)="adjustTextareaHeight($event)"
			></textarea>

			<div class="content-options">
				<div class="row">
					<div class="col">
						<div class="vertical-align-left">
							<div *ngIf="!loadings.search">
								<i
									*ngIf="!settings.listening"
									class="text-muted bi bi-mic-fill"
									(click)="startListening()"
								></i>
								<i
									*ngIf="settings.listening"
									class="text-muted bi bi-stop-circle"
									(click)="stopListening()"
								></i>
								<i
									[ngClass]="{ 'bi-disable': !prompt }"
									*ngIf="!settings.listening"
									class="text-muted bi bi-send"
									(click)="sendMessage()"
								></i>
							</div>
							<div style="margin-left: 10px" *ngIf="settings.listening">Escuchando {{ dots }}</div>
							<i
								*ngIf="loadings.search"
								(click)="cancelSearch()"
								class="text-muted bi bi-stop-circle-fill"
							></i>
						</div>
					</div>
					<div class="col-auto text-muted fs-12 vertical-align">POWERED BY ZEYO</div>
				</div>
			</div>
		</div>
		<!-- CONTENIDO DE INGRESO DE DATOS -->
	</section>
</div>
