/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ZeyoChatDto } from '../interfaces/zeyo-ia';

// const BASE_URL = 'zeyo-ia';
const BASE_URL = environment.apirUrlIA;

@Injectable({
	providedIn: 'root'
})
export class ZeyoIaService {
	constructor(private _authenticationService: AuthenticationService) {}

	getChatStream(zeyoChatDto: ZeyoChatDto, chatHistory: any[]): Observable<string> {
		zeyoChatDto.variables = {
			groupCode: this._authenticationService.currentUser.group_code,
			chatHistory
		};
		const URL = `${BASE_URL}/chat`;
		return new Observable((observer) => {
			fetch(URL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${this._authenticationService.currentUser.access_token}`
				},
				body: JSON.stringify(zeyoChatDto)
			})
				.then(async (response) => {
					const reader = response.body?.getReader();
					const decoder = new TextDecoder();

					if (!reader) {
						observer.error('No se pudo obtener el lector del stream');
						return;
					}

					while (true) {
						const { done, value } = await reader.read();

						// console.error('SERVICE');
						// console.error('DONE');
						// console.error(done);
						// console.error('VALUE');
						// console.error(value);
						if (done) break;
						// if (!value) break;
						// if (value) {
						const chunk = decoder.decode(value, { stream: true });
						// console.error('CHUNK');
						// console.error(chunk);
						// console.error('--------');
						observer.next(chunk);
						// }
					}
					observer.complete();
				})
				.catch((error) => observer.error(error));
		});
	}
}
