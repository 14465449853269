<div class="modal fade" id="confirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body p-2">
				<h5><strong>¿Estás seguro que deseas eliminar este Nodo?</strong></h5>
				<p>Se eliminarán los nodos hijos</p>
			</div>
			<div class="modal-footer">
				<button data-bs-dismiss="modal" class="btn btn-outline-primary">Cancelar</button>
				<button data-bs-dismiss="modal" (click)="confirm.emit()" class="ml-1 btn btn-primary">Confirmar</button>
			</div>
		</div>
	</div>
</div>
