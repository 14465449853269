<div
	class="modal fade"
	id="dropzone"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
>
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body p-2">
				<ngx-dropzone
					[multiple]="false"
					accept="image/jpeg,image/jpg,image/png"
					[maxFileSize]="5000000"
					(change)="onSelect($event)"
				>
					<ngx-dropzone-label>
						<i class="bi bi-upload"></i>
						<div class="fw-bold">
							{{ 'account.logo.ngxDropzoneLabel' | translate }}
						</div>
						<div>JPG, JPEG, PNG</div>
					</ngx-dropzone-label>

					<ngx-dropzone-image-preview
						ngProjectAs="ngx-dropzone-preview"
						*ngFor="let f of addedFiles"
						[file]="f"
						[removable]="true"
						(removed)="onRemove(f)"
					>
					</ngx-dropzone-image-preview>
				</ngx-dropzone>
			</div>
			<div class="modal-footer">
				<button data-bs-dismiss="modal" class="btn btn-outline-primary">
					{{ 'miscellaneous.buttons.return' | translate }}
				</button>
				<button
					data-bs-dismiss="modal"
					(click)="selected.emit(this.addedFiles[0])"
					[disabled]="addedFiles.length === 0"
					class="ml-1 btn btn-primary"
				>
					{{ 'miscellaneous.buttons.add' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
