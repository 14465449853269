<div class="modal fade" id="addLogo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body p-2">
				<div class="fw-bold text-center row m-0">
					<div class="col-auto">
						<span
							(click)="emitters.appearanceEmiter.emit(true)"
							data-bs-dismiss="modal"
							role="button"
							class="text-secondary fw-500"
						>
							{{ 'miscellaneous.buttons.back' | translate }}
						</span>
					</div>
					<div class="col text-center">
						<span class="fs-20">
							{{ 'modules.account.modals.add-logo-modal.title' | translate }}
						</span>
					</div>
					<div class="col-auto">
						<span #close data-bs-dismiss="modal" role="button" class="text-secondary fw-500">{{
							'miscellaneous.buttons.exit' | translate
						}}</span>
					</div>
				</div>
				<div class="hr"></div>
				<ngx-dropzone
					*ngIf="!selected"
					[disabled]="settings.save"
					[multiple]="false"
					accept="image/jpeg,image/jpg,image/png"
					[maxFileSize]="5000000"
					(change)="onSelect($event)"
				>
					<ngx-dropzone-label>
						<i class="bi bi-upload"></i>
						<div class="fw-bold">
							{{ 'modules.account.modals.add-logo-modal.ngxDropzoneLabel' | translate }}
						</div>
						<div>JPG, JPEG, PNG</div>
					</ngx-dropzone-label>

					<ngx-dropzone-image-preview
						ngProjectAs="ngx-dropzone-preview"
						*ngFor="let f of addedFiles"
						[file]="f"
						[removable]="true"
						(removed)="onRemove(f)"
					>
					</ngx-dropzone-image-preview>
				</ngx-dropzone>
				<section class="content-selected text-center" *ngIf="selected">
					<img
						class="imgSelected"
						[src]="selected.url"
						alt="img"
						onerror="this.src='../../../../../assets/imgs/temp/timeline1.png'"
					/>
					<br />
					<button class="btn btn-primary mt-1" (click)="chooseLogo()">
						{{ 'modules.account.modals.add-logo-modal.add' | translate }}
					</button>
				</section>
				<br />
				<section *ngIf="settings.loading">
					<div class="text-center">
						<div class="spinner-border text-primary" role="status"></div>
						<div class="fs-14">{{ 'miscellaneous.loading' | translate }}</div>
					</div>
				</section>
				<section *ngIf="!settings.loading">
					<section *ngIf="!settings.status" class="text-center">
						<div class="text-muted">
							{{ 'modules.account.modals.add-logo-modal.no_access_photos' | translate }}
						</div>
						<br />
						<div (click)="getPictures()" role="button" class="text-primary">
							{{ 'warnings.reinvent' | translate }}
						</div>
					</section>
					<section *ngIf="settings.status">
						<div *ngIf="pictures.length > 0">
							<div class="row">
								<div class="col fw-bold">
									{{ 'modules.account.modals.add-logo-modal.files_uploaded' | translate }}
								</div>
								<div class="col-auto">
									<div
										data-bs-toggle="modal"
										data-bs-target="#viewAllImages"
										role="button"
										class="text-primary"
									>
										{{ 'modules.account.modals.add-logo-modal.view_all' | translate }}
									</div>
								</div>
							</div>
							<br />
							<div class="view vertical-align-left">
								<img
									*ngFor="let picture of pictures"
									class="img"
									(click)="chooseImage(picture)"
									[ngClass]="{ selected: picture.selected }"
									[src]="picture.url"
									onerror="this.src='../../../../../assets/imgs/temp/timeline1.png'"
									alt="view"
								/>
							</div>
						</div>
						<div *ngIf="pictures.length === 0" class="text-muted text-center">
							{{ 'modules.account.modals.add-logo-modal.no_photos' | translate }}
						</div>
					</section>
				</section>
			</div>
			<div class="modal-footer">
				<button (click)="save()" [disabled]="settings.save" class="btn btn-primary">
					<span *ngIf="!settings.save">{{
						'modules.account.modals.add-logo-modal.buttons.apply' | translate
					}}</span>
					<span *ngIf="this.settings.save">
						<div class="spinner-loading-button spinner-border text-white" role="status"></div>
					</span>
				</button>
			</div>
		</div>
	</div>
</div>

<app-view-all-images-modal [images]="this.pictures"></app-view-all-images-modal>
