import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GROUP_TYPE, ROLE } from 'src/app/core/enums';
import { Menu } from 'src/app/core/interfaces/menu';
import { MethodsService } from 'src/app/core/utils/methods.service';
import { InstanceImageData } from '../../core/interfaces/instance-image';
import { QueryOptions } from '../../core/interfaces/query-options';
import { AnalyticUsersService } from '../../core/services/analytic-users.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { InstanceImageService } from '../../core/services/instance-image.service';
import { WorkspaceProcessService } from '../../core/services/workspace-process.service';
import { EmittersService } from '../../core/utils/emitters.service';
import { StorageService } from '../../core/utils/storage.service';
import { EN, ES } from './menu';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
	menu: Menu[] = ES;
	screen = screen;
	toogle = true;
	lang: string;

	logo: InstanceImageData;
	settings = {
		loading: true,
		status: false
	};

	loaings = {
		infoPlan: 'loading'
	};

	constructor(
		public router: Router,
		public authenticationService: AuthenticationService,
		public methodsService: MethodsService,
		public storageService: StorageService,
		private _workspaceProcessService: WorkspaceProcessService,
		private _emitterService: EmittersService,
		private _translate: TranslateService,
		private _analyticUsersService: AnalyticUsersService,
		private _instanceImageService: InstanceImageService
	) {
		this._emitterService.languajeEmiter.subscribe(() => this.setLanguaje());
		this._emitterService.logoEmiter.subscribe(() => this.getLogo());
		this.setLanguaje();
		this.getLogo();
		this.getProcesses();
		if (this.screen.width < 900) this.toogle = false;
	}

	setToogle(): void {
		this.toogle = !this.toogle;
		localStorage.setItem('toogle', JSON.stringify(this.toogle));
	}

	getProcesses(): void {
		const workspace = this.storageService.getStorage('workspace');
		if (workspace) {
			const data = this.storageService.getDataStorage('workspace');
			this.authenticationService.workspace = data;
			this.authenticationService.currentUser.instance_id = data.instanceId;
			for (const iterator of this.menu) {
				if (iterator.link === '/processes') {
					iterator.childrens = [];
					(iterator.loading = true), (iterator.status = false);
				}
			}
			const queryOptions: QueryOptions = {
				relations: 'customProcess'
			};
			this._workspaceProcessService
				.getProcessesByWorkspace(GROUP_TYPE.BUSINESS, data.code, data.id, queryOptions)
				.subscribe({
					next: (response) => {
						for (const iterator of this.menu) {
							if (iterator.link === '/processes') {
								for (const data of response.data) {
									iterator.childrens.push({
										title: data.customProcess.name,
										type: 'basic',
										icon: 'bi-arrow-right',
										link: '/processes/process/' + data.id
									});
								}
								iterator.loading = false;
								iterator.status = true;
							}
						}
					},
					error: (err) => {
						for (const iterator of this.menu) {
							if (iterator.link === '/processes') {
								iterator.loading = false;
								iterator.status = false;
							}
						}
					}
				});
		} else {
			for (const iterator of this.menu) {
				if (iterator.link === '/processes') {
					iterator.loading = false;
					iterator.status = false;
				}
			}
		}
	}

	getLogo(): void {
		this.settings.loading = true;
		this._instanceImageService
			.getImagesByInstanceID(this.authenticationService.currentUser.instance_id, {
				boolField: 'enabled',
				boolValue: true
			})
			.subscribe({
				next: (response) => {
					this.authenticationService.logo = response.data[0];
					this.logo = response.data[0];
					this.settings.status = true;
				},
				error: (err) => (this.settings.status = false)
			})
			.add(() => (this.settings.loading = false));
	}

	setLanguaje(): void {
		let MENU: Menu[];
		this.lang = this._translate.currentLang;
		this.lang === 'en' ? (MENU = EN) : (MENU = ES);
		this.menu = [];
		for (const menu of MENU) {
			for (const rol of menu.role) {
				if (rol === this.authenticationService.selectedRole) this.menu.push(menu);
			}
		}
		this.setAccount();
	}

	setAccount(): void {
		if (this.screen.width < 900) {
			this.toogle = false;
			if (this.lang === 'en') {
				this.menu.push({
					title: 'Account',
					type: 'basic',
					role: [ROLE.ADMINISTRATOR, ROLE.COLLABORATOR],
					icon: 'bi-person-circle',
					loading: false,
					status: true,
					link: '/account'
				});
			} else {
				this.menu.push({
					title: 'Cuenta',
					type: 'basic',
					role: [ROLE.ADMINISTRATOR, ROLE.COLLABORATOR],
					icon: 'bi-person-circle',
					loading: false,
					status: true,
					link: '/account'
				});
			}
			//delete duplicate element
			if (this.menu.length > 6) {
				this.menu.pop();
			}
		}
		this.analyticCheckPermission();
	}

	routerLinkActive(menu: any): boolean {
		return this.router.url.includes(menu.link);
	}

	goTo(menu: any): any {
		if (menu.type === 'basic') {
			const title = menu.title.split(' ');
			if (title[0] === 'Proceso') this._emitterService.processEmiter.emit(true);
			this.router.navigate([menu.link]);
			if (this.screen.width < 900) this.toogle = false;
		}
		if (menu.type === 'group') {
			menu.toogle = !menu.toogle;
		}
	}

	analyticCheckPermission(): void {
		if (ROLE.ADMINISTRATOR === this.authenticationService.selectedRole)
			this.menu.unshift({
				title: 'Analíticas',
				type: 'basic',
				role: [ROLE.ADMINISTRATOR, ROLE.COLLABORATOR],
				icon: 'bi-house-door',
				loading: false,
				status: true,
				link: '/dashboard'
			});
		else {
			this._analyticUsersService
				.checkPermission(
					this.authenticationService.workspace.business.businessId,
					this.authenticationService.workspace.id,
					this.authenticationService.currentUser.user.email
				)
				.subscribe({
					next: (res) => {
						this.authenticationService.currentUser.access_analytics = res.result;
						if (res.result) {
							this.menu.unshift({
								title: 'Analíticas',
								type: 'basic',
								role: [ROLE.ADMINISTRATOR, ROLE.COLLABORATOR],
								icon: 'bi-house-door',
								loading: false,
								status: true,
								link: '/dashboard'
							});
						}
					}
				});
		}
	}

	signout(): void {
		this.authenticationService.signout();
	}
}
