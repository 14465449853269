import { environment } from 'src/environments/environment';

export const servicesConfig = {
	analytic: {
		mainEndpoint: 'analytic',
		routes: {
			resume: 'resume',
			detail: 'detail'
		}
	},
	analytic_users: {
		mainEndpoint: 'analytic-users',
		routes: {
			user_workspace: 'user-workspace',
			check: 'check'
		}
	},
	auth: {
		mainEndpoint: 'auth',
		routes: {
			token: 'token'
		}
	},
	api: {
		url: `${environment.apiUrl}`
	},
	business: {
		mainEndpoint: 'business',
		routes: {
			instance: 'instance',
			enableCollaboratorCompany: 'enable-collaborator-in-company',
			disableCollaboratorCompany: 'disable-collaborator-in-company',
			user: 'user',
			externalUniqueId: 'external-unique-id'
		}
	},
	campaign: {
		mainEndpoint: 'campaign'
	},
	campaignFile: {
		mainEndpoint: 'campaign-file'
	},
	catalogDetail: {
		mainEndpoint: 'catalog-detail/',
		routes: {
			catalog: 'catalog',
			description: 'description',
			country: 'country',
			businessType: 'businessType',
			typeIdentification: 'typeIdentification'
		}
	},
	chain: {
		mainEndpoint: 'chain'
	},
	communication_channel: {
		mainEndpoint: 'communication-channel',
		routes: {
			traceability_notification: 'traceability-notification'
		}
	},
	component_data: {
		mainEndpoint: 'component-data',
		routes: {
			traces: 'traces',
			timelines: 'timelines'
		}
	},
	component_data_update: {
		mainEndpoint: 'component-data-update',
		routes: {
			requests: 'requests',
			changes: 'changes',
			approved: 'approved',
			reject: 'reject',
			changes_hashes: 'changes-hashes',
			changes_definition: 'changes-definition'
		}
	},
	custom_process: {
		mainEndpoint: 'custom-process'
	},
	data: {
		mainEndpoint: 'data',
		routes: {
			data_hash: 'data-hash',
			codes: 'codes',
			public: 'public'
		}
	},
	data_hash: {
		mainEndpoint: 'data-hash'
	},
	definition_role: {
		mainEndpoint: 'definition-role'
	},
	emailInvite: {
		mainEndpoint: 'email-invite',
		routes: {
			instance: 'instance'
		}
	},
	emailRegister: {
		mainEndpoint: 'email-register'
	},
	event: {
		mainEndpoint: 'event'
	},
	explorer: {
		mainEndpoint: 'explorer',
		routes: {
			url: 'url'
		}
	},
	feedback: {
		mainEndpoint: 'feedback',
		routes: {
			instance: 'resume',
			setting: 'setting'
		}
	},
	feedback_settings: {
		mainEndpoint: 'feedback-setting'
	},
	field: {
		mainEndpoint: 'field',
		routes: {
			definition: 'definition'
		}
	},
	field_role: {
		mainEndpoint: 'field-role'
	},
	google: {
		translate: `${environment.google.apiTranslate}`,
		dectect: `${environment.google.apiDetect}`
	},
	instanceImage: {
		mainEndpoint: 'instance-image',
		routes: {
			user: 'user'
		}
	},
	qr: {
		mainEndpoint: 'qr',
		routes: {
			byDomainAndQRCode: 'byDomainAndQRCode'
		}
	},
	languageHandling: {
		mainEndpoint: 'language-handling',
		routes: {
			user: 'user'
		}
	},
	processDefinition: {
		mainEndpoint: 'process-definition',
		routes: {
			inputs: 'inputs',
			origins: 'origins'
		}
	},
	// processEvent: {
	// 	mainEndpoint: 'process-event',
	// 	routes: {
	// 		inputs: 'inputs'
	// 	}
	// },
	processProduct: {
		mainEndpoint: 'process-product'
	},
	user: {
		mainEndpoint: 'user/',
		routes: {
			signUpAdmin: 'sign-up-admin/',
			signUpCollaborator: 'sign-up-collaborator',
			instance: 'instance',
			business: 'business',
			social: 'social'
		}
	},
	userType: {
		mainEndpoint: 'user-type'
	},
	userVerify: {
		mainEndpoint: 'user-verify/',
		routes: {
			admin: 'admin/'
		}
	},
	userEmail: {
		mainEndpoint: 'user-email/',
		routes: {
			business: 'business/'
		}
	},
	shared_role: {
		mainEndpoint: 'shared-role',
		routes: {
			instance: 'instance',
			public_role: 'public-role'
		}
	},
	workspace: {
		mainEndpoint: 'workspace',
		routes: {
			user: 'user',
			byBusiness: 'by-business/'
		}
	},
	workspace_invitation: {
		mainEndpoint: 'workspace-invitation',
		routes: {
			resend: 'resend'
		}
	},
	workspaceProcess: {
		mainEndpoint: 'workspace-process',
		routes: {
			byWorkspace: 'by-workspace/',
			nextCompaniesEvent: 'next-companies-event'
		}
	},
	workspace_user: {
		mainEndpoint: 'workspace-user'
	},
	wsProcessComponent: {
		mainEndpoint: 'ws-process-component',
		routes: {
			next_companies_event: 'next-companies-event',
			read: 'read',
			selected_business: 'selected-business',
			origins: 'origins'
		}
	},
	workspace_jwt_sign: {
		mainEndpoint: 'workspace-jwt-sign'
	},
	workspacesBussines: {
		mainEndpoint: 'workspace-business',
		routes: {
			getByWorkspace: 'by-workspace'
		}
	},
	wsUserBusinessRole: {
		mainEndpoint: 'ws-user-business-role',
		routes: {
			events: 'event',
			definition: 'definition',
			role: 'field-role',
			companies: 'read',
			defRole: 'definition-role'
		}
	},
	wsUserBusinessRoleBatch: {
		mainEndpoint: 'ws-user-business-role-batch',
		routes: {
			definitionRole: 'definition-role',
			fieldRole: 'field-role'
		}
	},
	wsProcessComponentRole: {
		mainEndpoint: 'ws-process-component-role',
		routes: {
			read: 'read',
			write: 'write',
			add: 'add-component-and-role'
		}
	},
	accountableEntity: {
		mainEndpoint: 'accountable-entities'
	},
	products: {
		mainEndpoint: 'product',
		routes: {
			editFields: 'edit-fields'
		}
	},
	wsProcessDefinition: {
		mainEndpoint: 'ws-process-definition'
	},
	worspaceDefinition: {
		mainEndpoint: 'workspace-definition',
		routes: {
			byWorkspaceProcess: 'by-workspace-process'
		}
	},
	wsUserComponent: {
		mainEndpoint: 'ws-user-component'
	},
	componentDataInfo: {
		mainEndPoint: 'component-data-info'
	},
	definition: {
		mainEndpoint: 'definition'
	},
	subscription_dashboard: {
		mainEndpoint: 'subscription-dashboard',
		routes: {
			info_plan: 'info-plan',
			info_plan_by_feature_type: 'info-plan-by-feature-type'
		}
	},
	subscription: {
		mainEndpoint: 'subscription',
		routes: {
			public: 'public',
			plans: 'plans'
		}
	},
	payment: {
		mainEndpoint: 'payment',
		routes: {
			confirm: 'confirm',
			cancel_recurring_charge: 'cancel-recurring-charge'
		}
	},
	instance: {
		mainEndpoint: 'instance',
		routes: {
			colloborator: 'colloborator-in-instance'
		}
	},
	editTraza: {
		editTraza: environment.editTraza,
		reverseTrace: environment.traceReverse
	}
};
