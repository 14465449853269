<div
	class="content-alert fullscreen vertical-align"
	*ngIf="alertService.data"
	[@openClose]="alertService.data && alertService.data.show ? 'open' : 'closed'"
>
	<div class="content text-center">
		<i
			class="bi bi-x-lg text-right mt-1 ml-27 mb-1"
			style="cursor: pointer"
			data-bs-dismiss="modal"
			role="button"
			(click)="close()"
		></i>
		<div class="fs-18 fw-500 mt-1">{{ alertService.data.title }}</div>
		<div>{{ alertService.data.subtitle }}</div>
		<br />
		<input class="form-control" [(ngModel)]="text" type="text" placeholder="mail@example.com" />
		<br />
		<div class="text-right">
			<button class="btn btn-outline-light" (click)="close()">
				<i class="mr-1 bi bi-arrow-left"></i>
				{{ alertService.data.cancelButtonText }}
			</button>
			<button [disabled]="!text" class="ml-1 btn btn-primary" (click)="close()">
				<i class="mr-1 bi bi-check2-circle text-white"></i>
				{{ alertService.data.confirmButtonText }}
			</button>
		</div>
	</div>
</div>
