<section class="sidebar">
	<div class="item-top">
		<div class="row m-0">
			<div class="col vertical-align">
				<div class="logo-content">
					<div *ngIf="settings.loading" class="text-center">
						<div class="spinner-border text-primary" role="status"></div>
					</div>
					<div *ngIf="!settings.loading">
						<img
							*ngIf="settings.status && this.logo"
							class="logo"
							[src]="this.logo.url"
							onerror="this.src='./assets/imgs/logos/principal.png'"
							alt="Logo"
						/>
						<div *ngIf="!settings.status" class="text-center fs-14">
							<p class="text-muted">{{ 'warnings.no_photo' | translate }}</p>
							<div role="button" class="text-primary" (click)="getLogo()">
								{{ 'warnings.reinvent' | translate }}
							</div>
						</div>
					</div>
					<div
						[routerLink]="['/workspaces']"
						role="button"
						*ngIf="this.authenticationService.selectedRole === 'collaborator'"
						class="text-secondary"
					>
						{{ this.authenticationService.workspace.name }}
					</div>
				</div>
			</div>
			<div class="col-auto vertical-align" *ngIf="screen.width <= 900">
				<em (click)="setToogle()" class="fs-20 bi bi-list"></em>
			</div>
		</div>
	</div>
	<div *ngIf="toogle">
		<div *ngFor="let item of menu">
			<div *ngIf="item.loading" class="item m-0 text-center">
				<div class="spinner-loading-button spinner-border text-primary" role="status"></div>
			</div>
			<div *ngIf="!item.loading">
				<div *ngIf="item.status">
					<div
						class="row item m-0 fs-15"
						(click)="goTo(item)"
						[ngClass]="{ 'text-primary': this.routerLinkActive(item) }"
					>
						<div class="col-2 vertical-align">
							<em
								[ngClass]="{ 'bg-primary text-white': this.routerLinkActive(item) }"
								class="bi fs-20 icon"
								[class]="item.icon"
							></em>
						</div>
						<div class="col-8 vertical-align-left">
							{{ item.title }}
						</div>
						<div class="col-2 vertical-align" *ngIf="item.type === 'group'">
							<em *ngIf="!item.toogle" class="bi bi-caret-down-fill"></em>
							<em *ngIf="item.toogle" class="bi bi-caret-up-fill"></em>
						</div>
					</div>
					<div *ngIf="item.type === 'group' && item.toogle">
						<div class="subitem" *ngFor="let subitem of item.childrens" (click)="goTo(subitem)">
							<div class="row">
								<div
									class="col vertical-align-left ml-2"
									style="color: #505050"
									[ngClass]="{ 'text-primary': this.router.url.includes(subitem.link) }"
								>
									{{ subitem.title }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="item m-0 text-center" *ngIf="!item.status">
					<div (click)="getProcesses()" role="button" class="fs-14 text-primary">
						{{ 'warnings.reinvent' | translate }}
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="screen.width < 900">
			<hr class="m-0" />
			<div class="row item m-0" (click)="signout()">
				<div class="col-2 vertical-align">
					<em class="bi icon-signout fs-20 bi-box-arrow-left text-danger bg-soft-danger"></em>
				</div>
				<div class="col-8 vertical-align-left text-danger">
					{{ 'miscellaneous.buttons.signout' | translate }}
				</div>
			</div>
		</div>
	</div>
</section>
<div class="footer" *ngIf="screen.width > 900">
	<hr class="m-0" />
	<div class="row item m-0" (click)="signout()">
		<div class="col-2 vertical-align">
			<em class="icon-signout bi fs-20 bi-box-arrow-left text-danger"></em>
		</div>
		<div class="col-8 vertical-align-left text-danger">{{ 'miscellaneous.buttons.signout' | translate }}</div>
	</div>
</div>
