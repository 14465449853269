import { Component, Input } from '@angular/core';
import { CUSTOM_DATA_TYPES } from 'src/app/core/enums';
import { Archives } from 'src/app/core/interfaces/data';
import { EmittersService } from '../../core/utils/emitters.service';
import { MethodsService } from '../../core/utils/methods.service';

@Component({
	selector: 'app-archives-modal',
	templateUrl: './archives-modal.component.html',
	styleUrls: ['./archives-modal.component.scss']
})
export class ArchivesModalComponent {
	@Input() archives: Archives[];

	constructor(private _emittersService: EmittersService, private _methodsService: MethodsService) {
		this._emittersService.archivesModal.subscribe((res) => {
			this.archives = res;
		});
	}

	openUrl(archive: Archives): void {
		if (archive.customDataType) {
			if (archive.customDataType === CUSTOM_DATA_TYPES.SIAP) {
				this._methodsService.downloadPdfBase64(archive.url, archive.name);
			}
		} else {
			archive.private
				? this._methodsService.downloadPdfBase64(archive.url, archive.name)
				: window.open(archive.url, '_blank');
		}
	}
}
