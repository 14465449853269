import { Component, Input } from '@angular/core';
import { InstanceImageData } from '../../core/interfaces/instance-image';
import { EmittersService } from '../../core/utils/emitters.service';

@Component({
	selector: 'app-view-all-images-modal',
	templateUrl: './view-all-images-modal.component.html',
	styleUrls: ['./view-all-images-modal.component.scss']
})
export class ViewAllImagesModalComponent {
	@Input() images: InstanceImageData[];
	selected: InstanceImageData;
	constructor(public emitters: EmittersService) {}

	save(): void {
		this.emitters.addLogoEmiter.emit({
			status: true,
			value: this.selected
		});
	}

	chooseImage(image: InstanceImageData): void {
		for (const iterator of this.images) {
			if (iterator.id === image.id) {
				iterator.selected = true;
				this.selected = iterator;
			} else {
				iterator.selected = false;
			}
		}
		this.checkList();
	}

	checkList(): boolean {
		let response = true;
		for (const iterator of this.images) {
			if (iterator.selected) response = false;
		}
		return response;
	}
}
