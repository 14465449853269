import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-dropzone-modal',
	templateUrl: './dropzone-modal.component.html',
	styleUrls: ['./dropzone-modal.component.scss']
})
export class DropzoneModalComponent {
	@Output() selected = new EventEmitter();
	addedFiles: Array<File> = [];

	onSelect(event: any): void {
		this.addedFiles = [];
		event.addedFiles.forEach((file: File) => {
			this.addedFiles.push(file);
		});
	}

	onRemove(event: any): void {
		this.addedFiles.splice(this.addedFiles.indexOf(event), 1);
	}
}
