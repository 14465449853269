import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ROLE } from '../enums';
import { CurrentUser } from '../interfaces/auth';
import { InstanceImageData } from '../interfaces/instance-image';
import { PlanByFeatureType } from '../interfaces/subscription_dashboard';
import { WorkspaceUser } from '../interfaces/workspace';
import { JwtService } from '../jwt/jwt.service';
import { StorageService } from '../utils/storage.service';
import { servicesConfig } from './services.config';

const BASE_URL = servicesConfig.api.url + servicesConfig.auth.mainEndpoint;
@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	planByFeatureType: PlanByFeatureType;
	currentUser: CurrentUser;
	workspace: WorkspaceUser;
	selectedRole: ROLE;
	logo: InstanceImageData;
	sidebar = true;

	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _storageService: StorageService,
		private _jwt: JwtService
	) {}

	// POST METHODS
	signIn(credentials: any): Observable<CurrentUser> {
		return this._http.post<CurrentUser>(BASE_URL, credentials);
	}

	signInToken(token: string, credentials: any): Observable<CurrentUser> {
		const URL = `${BASE_URL}/${servicesConfig.auth.routes.token}/${token}`;
		return this._http.post<CurrentUser>(URL, credentials);
	}

	refreshToken(): any {
		if (this.currentUser) {
			const credentials = {
				refreshToken: this.currentUser.refresh_token,
				clientId: 'zeyo-cli',
				grantType: 'refresh_token'
			};
			this._http.post<any>(BASE_URL, credentials).subscribe({
				next: (response) => {
					const jwt = response;
					jwt.user = this._jwt.decodeToken(jwt.access_token);
					this._storageService.setStorage(environment.platform, response);
					this.currentUser = jwt;
					this.expiredTokenInterval(jwt.access_token);
				},
				error: () => this.signout()
			});
		}
	}

	forgotPassword(email: string): Observable<any> {
		const URL = `${BASE_URL}/recovery-password`;
		return this._http.post<any>(URL, { email });
	}
	// END POST METHODS

	// PUT METHODS
	restorePassword(body: any, userId: string, token: string): Observable<any> {
		const URL = `${BASE_URL}/reset-password/${userId}/${token}`;
		return this._http.put<any>(URL, body);
	}
	// END PUT METHODS

	expiredTokenInterval(token: string): void {
		const expirationDate = moment(this._jwt.expirationDate(token)).format('YYYY-MM-DD HH:mm');
		const currentDate = moment().add(3, 'm').format('YYYY-MM-DD HH:mm');
		currentDate >= expirationDate
			? this.refreshToken()
			: setTimeout(() => {
					this.expiredTokenInterval(token);
			  }, 2 * 60 * 1000);
	}

	signout(): void {
		this._storageService.clearStorage();
		this.selectedRole = undefined;
		this.currentUser = undefined;
		this.workspace = undefined;
		this._router.navigate(['/auth'], { replaceUrl: true });
	}
}
