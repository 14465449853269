import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ROLE } from '../enums';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AdministratorGuard implements CanActivate {
	constructor(private _authenticationService: AuthenticationService) {}

	canActivate(_route: ActivatedRouteSnapshot): boolean {
		const currentUser = this._authenticationService.currentUser;
		if (currentUser) return this._authenticationService.selectedRole === ROLE.ADMINISTRATOR;
		return false;
	}
}
