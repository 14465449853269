<!-- <div class="row">
	<div class="col">
		<app-sidebar></app-sidebar>
	</div>
	<div class="col p-2 page-content">
		<router-outlet></router-outlet>
	</div>
</div> -->
<app-sidebar></app-sidebar>
<div class="on p-2 page-content">
	<router-outlet></router-outlet>
</div>
