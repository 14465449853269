import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InstanceImageData } from 'src/app/core/interfaces/instance-image';
import { AuthenticationService } from '../../core/services/authentication.service';
import { InstanceImageService } from '../../core/services/instance-image.service';
import { EmittersService } from '../../core/utils/emitters.service';
import { ToastService, toastTypes } from '../../shared/toasts/toast/toast.service';

@Component({
	selector: 'app-add-logo-modal',
	templateUrl: './add-logo-modal.component.html',
	styleUrls: ['./add-logo-modal.component.scss']
})
export class AddLogoModalComponent {
	@ViewChild('close') close: ElementRef;
	@Input() selected: InstanceImageData;
	addedFiles: Array<File> = [];
	pictures: InstanceImageData[] = [];
	settings = {
		save: false,
		loading: false,
		status: true
	};
	constructor(
		public emitters: EmittersService,
		private _instanceImageService: InstanceImageService,
		private _toast: ToastService,
		private _authenticationService: AuthenticationService,
		private _translateService: TranslateService
	) {
		this.getPictures();
	}

	onSelect(event: any): void {
		this.addedFiles = [];
		event.addedFiles.forEach((file: File) => {
			this.addedFiles.push(file);
		});
	}

	onRemove(event: any): void {
		this.addedFiles.splice(this.addedFiles.indexOf(event), 1);
	}

	save(): void {
		if (this.addedFiles.length === 0 && !this.selected) {
			const body = {
				title: 'Fotografía de la Empresa',
				message: 'Debes seleccionar una fotografía para continuar.'
			};
			if (this._translateService.currentLang === 'en') {
				body.title = 'Company Photography';
				body.message = 'You must select a photo to continue.';
			}

			this._toast.initiate({
				title: body.title,
				message: body.message,
				type: toastTypes.warning
			});
			return;
		}
		this.settings.save = true;
		if (this.selected) {
			this._instanceImageService
				.updateImage(this.selected.id)
				.subscribe({
					next: (response) => {
						const body = {
							title: 'Fotografía de la Empresa',
							message: 'La fotografia de perfil ha sido modificada.'
						};
						if (this._translateService.currentLang === 'en') {
							body.title = 'Company Photography';
							body.message = 'Profile photo has been modified';
						}
						this._toast.initiate({
							title: body.title,
							message: body.message,
							type: toastTypes.success
						});
						this.selected = undefined;
						this.emitters.logoEmiter.emit(true);
						this.close.nativeElement.click();
					},
					error: (err) => {
						let title = 'Fotografía de la Empresa';
						if (this._translateService.currentLang === 'en') title = 'Company Photography';
						this._toast.initiate({
							title,
							message: err.description,
							type: toastTypes.error
						});
					}
				})
				.add(() => (this.settings.save = false));
		} else {
			this._instanceImageService
				.createImage(this.addedFiles[0])
				.subscribe({
					next: (response) => {
						this.pictures.push(response.raw);
						this.addedFiles = [];
						const body = {
							title: 'Fotografía de la Empresa',
							message: 'La fotografia de perfil ha sido modificada.'
						};
						if (this._translateService.currentLang === 'en') {
							body.title = 'Company Photography';
							body.message = 'Profile photo has been modified';
						}
						this._toast.initiate({
							title: body.title,
							message: body.message,
							type: toastTypes.success
						});
						this.emitters.logoEmiter.emit(true);
						this.close.nativeElement.click();
					},
					error: (err) => {
						let title = 'Fotografía de la Empresa';
						if (this._translateService.currentLang === 'en') title = 'Company Photography';
						this._toast.initiate({
							title,
							message: err.description,
							type: toastTypes.error
						});
					}
				})
				.add(() => (this.settings.save = false));
		}
	}

	getPictures(): void {
		this.settings.loading = true;
		this._instanceImageService
			.getImagesByInstanceID(this._authenticationService.currentUser.instance_id)
			.subscribe({
				next: (response) => {
					this.pictures = response.data;
					this.settings.status = true;
				},
				error: (err) => (this.settings.status = false)
			})
			.add(() => (this.settings.loading = false));
	}

	chooseImage(image: InstanceImageData): void {
		for (const iterator of this.pictures) {
			if (iterator.id === image.id) {
				iterator.selected = true;
				this.selected = iterator;
			} else {
				iterator.selected = false;
			}
		}
	}

	chooseLogo(): void {
		this.selected = undefined;
		for (const iterator of this.pictures) {
			iterator.selected = false;
		}
	}
}
