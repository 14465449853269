<div class="modal fade" id="picture" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">{{ 'modals.pictures_modal.image' | translate }}</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body text-center">
				<img id="im" src="" alt="img" />
			</div>
			<div class="modal-footer">
				<button data-bs-dismiss="modal" class="btn btn-outline-primary">
					{{ 'modals.pictures_modal.close' | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
