import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
	selector: 'app-picture-modal',
	templateUrl: './picture-modal.component.html',
	styleUrls: ['./picture-modal.component.scss']
})
export class PictureModalComponent implements OnChanges {
	@Input() img: any;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.img.currentValue) document.getElementById('im').setAttribute('src', changes.img.currentValue);
	}
}
