import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../interfaces/auth';

const jwtHelperService = new JwtHelperService();

@Injectable({
	providedIn: 'root'
})
export class JwtService {
	decodeToken(token: string): User {
		return jwtHelperService.decodeToken(token);
	}

	expirationDate(token: string): Date {
		return jwtHelperService.getTokenExpirationDate(token);
	}

	isExpiredToken(token: string): boolean {
		return jwtHelperService.isTokenExpired(token);
	}
}
