import { Component, ElementRef, ViewChild } from '@angular/core';
import { InstanceImageData } from 'src/app/core/interfaces/instance-image';
import { EmittersService } from '../../core/utils/emitters.service';
import { MethodsService } from '../../core/utils/methods.service';

@Component({
	selector: 'app-appearance-modal',
	templateUrl: './appearance-modal.component.html',
	styleUrls: ['./appearance-modal.component.scss']
})
export class AppearanceModalComponent {
	@ViewChild('logo') logo: ElementRef;
	picture: InstanceImageData;
	constructor(public emitters: EmittersService, public methodsService: MethodsService) {
		this.emitters.addLogoEmiter.subscribe((data) => {
			if (data.status) this.picture = data.value;
			setTimeout(() => {
				this.logo.nativeElement.click();
			}, 100);
		});
	}
}
