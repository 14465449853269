import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	setStorage(title: string, data: any): void {
		const encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), environment.criptoSecretKey).toString();
		localStorage.setItem(title, encrypt);
	}

	getStorage(title: string): boolean {
		const storage = localStorage.getItem(title) || '';
		const bytes = CryptoJS.AES.decrypt(storage, environment.criptoSecretKey);
		const data = bytes.toString(CryptoJS.enc.Utf8);
		if (data) {
			return true;
		}
		return false;
	}

	getDataStorage(title: string): any {
		const storage = localStorage.getItem(title) || '';
		const bytes = CryptoJS.AES.decrypt(storage, environment.criptoSecretKey);
		const data = bytes.toString(CryptoJS.enc.Utf8);
		if (data) {
			return JSON.parse(data);
		}
		return data;
	}

	clearStorage(): void {
		localStorage.clear();
	}

	removeStorage(title: string): void {
		localStorage.removeItem(title);
	}
}
