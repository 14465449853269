import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Loading {
	header?: string;
	button?: string;
	_show?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class LoadingService {
	data!: Loading;
	public open = new Subject<any>();

	// Instantiate and initialize the loading
	// @autor: Carlos Sánchez Mora
	// @date: 29-08-2022
	show(data?: Loading): any {
		this.data = { ...data, _show: true };
		this.open.next(this.data);
	}

	// Close the loading
	// @autor: Carlos Sánchez Mora
	// @date: 29-08-2022
	hide(response?: boolean): any {
		this.data = { ...this.data, _show: false };
		this.open.next(response);
	}
}
