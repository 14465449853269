import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
	@Output() confirm = new EventEmitter();
}
