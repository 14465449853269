import { Component, ElementRef, ViewChild } from '@angular/core';
import { EmittersService } from '../../core/utils/emitters.service';

@Component({
	selector: 'app-preferences-modal',
	templateUrl: './preferences-modal.component.html',
	styleUrls: ['./preferences-modal.component.scss']
})
export class PreferencesModalComponent {
	@ViewChild('appearance') appearance: ElementRef;
	screen = screen;
	constructor(private _emitters: EmittersService) {
		this._emitters.appearanceEmiter.subscribe(() => {
			this.appearance.nativeElement.click();
		});
	}
}
