import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ROLE } from '../enums';
import { MethodsService } from '../utils/methods.service';

@Injectable({
	providedIn: 'root'
})
export class CollaboratorGuard implements CanActivate {
	constructor(private _authenticationService: AuthenticationService, private _methodsService: MethodsService) {}

	canActivate(_route: ActivatedRouteSnapshot): boolean {
		const currentUser = this._authenticationService.currentUser;
		if (currentUser) {
			return this._authenticationService.selectedRole === ROLE.COLLABORATOR;
		}
		return false;
	}
}
