import { ROLE } from 'src/app/core/enums';
import { Menu } from 'src/app/core/interfaces/menu';

export const ES: Menu[] = [
	{
		title: 'Espacios',
		type: 'basic',
		role: [ROLE.ADMINISTRATOR],
		icon: 'bi-boxes',
		loading: false,
		status: true,
		link: '/spaces'
	},
	{
		title: 'Eventos',
		type: 'basic',
		role: [ROLE.ADMINISTRATOR],
		icon: 'bi-archive',
		loading: false,
		status: true,
		link: '/events'
	},
	{
		title: 'Procesos',
		type: 'basic',
		role: [ROLE.ADMINISTRATOR],
		icon: 'bi-diagram-3',
		loading: false,
		status: true,
		link: '/operations'
	},
	{
		title: 'Procesos',
		type: 'group',
		role: [ROLE.COLLABORATOR],
		icon: 'bi-activity',
		loading: true,
		status: false,
		link: '/processes',
		childrens: []
	},
	{
		title: 'Trazas',
		type: 'basic',
		role: [ROLE.COLLABORATOR, ROLE.ADMINISTRATOR],
		icon: 'bi-activity',
		loading: false,
		status: true,
		link: '/traces'
	},
	{
		title: 'Productos',
		role: [ROLE.ADMINISTRATOR],
		type: 'basic',
		icon: 'bi-box',
		loading: false,
		status: true,
		link: '/products'
	},
	{
		title: 'Administrador',
		type: 'group',
		role: [ROLE.ADMINISTRATOR],
		link: '/administrator',
		icon: 'bi-people-fill',
		loading: false,
		status: true,
		childrens: [
			{
				title: 'Usuarios',
				type: 'basic',
				icon: 'bi-arrow-right',
				link: '/administrator/users'
			},
			{
				title: 'Empresas',
				type: 'basic',
				icon: 'bi-arrow-right',
				link: '/administrator/companies'
			},
			{
				title: 'Roles',
				type: 'basic',
				icon: 'bi-arrow-right',
				link: '/administrator/roles'
			}
		]
	}
];

export const EN: Menu[] = [
	{
		title: 'Spaces',
		type: 'basic',
		role: [ROLE.ADMINISTRATOR],
		icon: 'bi-boxes',
		loading: false,
		status: true,
		link: '/spaces'
	},
	{
		title: 'Events',
		type: 'basic',
		role: [ROLE.ADMINISTRATOR],
		icon: 'bi-archive',
		loading: false,
		status: true,
		link: '/events'
	},
	{
		title: 'Processes',
		type: 'basic',
		role: [ROLE.ADMINISTRATOR],
		icon: 'bi-diagram-3',
		loading: false,
		status: true,
		link: '/operations'
	},
	{
		title: 'Processes',
		type: 'group',
		role: [ROLE.COLLABORATOR],
		link: '/processes',
		icon: 'bi-activity',
		loading: true,
		status: false,
		childrens: []
	},
	{
		title: 'Traces',
		type: 'basic',
		role: [ROLE.COLLABORATOR, ROLE.ADMINISTRATOR],
		icon: 'bi-activity',
		loading: false,
		status: true,
		link: '/traces'
	},
	{
		title: 'Products',
		role: [ROLE.ADMINISTRATOR],
		type: 'basic',
		icon: 'bi-box',
		loading: false,
		status: true,
		link: '/products'
	},

	{
		title: 'Administrator',
		type: 'group',
		role: [ROLE.ADMINISTRATOR],
		link: '/administrator',
		icon: 'bi-people-fill',
		loading: false,
		status: true,
		childrens: [
			{
				title: 'Users',
				type: 'basic',
				icon: 'bi-arrow-right',
				link: '/administrator/users'
			},
			{
				title: 'Companies',
				type: 'basic',
				icon: 'bi-arrow-right',
				link: '/administrator/companies'
			},
			{
				title: 'Roles',
				type: 'basic',
				icon: 'bi-arrow-right',
				link: '/administrator/roles'
			}
		]
	}
	/*,
	{
		title: 'Account',
		type: 'basic',
		icon: 'bi-person-circle',
		loading: false,
		status: true,
		link: '/account'
	}*/
];
