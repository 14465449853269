import { Injectable } from '@angular/core';
import { servicesConfig } from './services.config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GROUP_TYPE } from '../enums';
import { GetAnalyticUsers, PostAnalyticUsersDTO, CheckPermission } from '../interfaces/analytics-users';

const BASE_URL = servicesConfig.api.url + servicesConfig.analytic_users.mainEndpoint;

@Injectable({
	providedIn: 'root'
})
export class AnalyticUsersService {
	constructor(private _http: HttpClient) {}

	// GET METHODS
	getAnalyticUsers(groupType: GROUP_TYPE, groupCode: string): Observable<GetAnalyticUsers[]> {
		const URL = `${BASE_URL}/${groupType}/${groupCode}`;
		return this._http.get<GetAnalyticUsers[]>(URL);
	}
	checkPermission(businessId: string, workspaceId: string, email: string): Observable<CheckPermission> {
		const URL = `${BASE_URL}/${servicesConfig.analytic_users.routes.user_workspace}/${servicesConfig.analytic_users.routes.check}/${businessId}/${workspaceId}/${email}`;
		return this._http.get<CheckPermission>(URL);
	}
	// END GET METHODS

	// POST METHODS
	postAnalyticUsers(dto: PostAnalyticUsersDTO): Observable<any> {
		return this._http.post<any>(BASE_URL, dto);
	}
	// END POST METHODS
}
