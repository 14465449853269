/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { EmittersService } from '../../../core/utils/emitters.service';
import { StorageService } from '../../../core/utils/storage.service';
import { ZeyoChatDto } from '../../core/interfaces/zeyo-ia';
import { SpeechRecognitionService } from '../../core/services/speech-recognition.service';
import { ZeyoIaService } from '../../core/services/zeyo-ia.service';

interface Chat {
	configurations: {
		message: string;
		loading: 'loading' | 'success' | 'error';
	};
	message: any;
	how: 'me' | 'ia';
	answer: any;
}
@Component({
	selector: 'app-agent',
	templateUrl: './agent.component.html',
	styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {
	@ViewChild('textarea') textarea!: ElementRef;
	@ViewChild('textarea') textareaHTMLTextAreaElement!: ElementRef<HTMLTextAreaElement>;
	@ViewChild('contentResponse') contentResponse!: ElementRef;

	loadings = {
		search: false
	};

	settings = {
		listening: false,
		show: false,
		notification: false
	};

	prompt = '';
	dots = '';
	chatHistory: any[] = [];

	chats: Chat[] = [];

	constructor(
		public authenticationService: AuthenticationService,
		private _zeyoIaService: ZeyoIaService,
		private _speechRecognitionService: SpeechRecognitionService,
		private _emittersService: EmittersService,
		private _router: Router,
		private _storageService: StorageService,
		private _domSanitizer: DomSanitizer
	) {}

	ngOnInit(): void {
		this._animateDots();
		this.chats.push({
			message: this._domSanitizer.bypassSecurityTrustHtml('Hola, en qué puedo ayudarte?'),
			how: 'ia',
			configurations: {
				message: '',
				loading: 'success'
			},
			answer: ''
		});
	}

	adjustTextareaHeight(event: Event): void {
		const textarea = event.target as HTMLTextAreaElement;
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight}px`;
		if (textarea.scrollHeight > 30 * 16) textarea.style.height = '15rem';
	}

	async sendMessage() {
		if (this.prompt.trim() === '') return;
		if (this.prompt.length === 0) return;
		if (this.loadings.search) return;
		this.loadings.search = true;

		this.chats.push({
			message: this._domSanitizer.bypassSecurityTrustHtml(this.textareaHTMLTextAreaElement.nativeElement.value),
			how: 'me',
			configurations: {
				message: '',
				loading: 'success'
			},
			answer: ''
		});

		const zeyoChatDto: ZeyoChatDto = {
			prompt: this.prompt
		};

		this.chats.push({
			message: '',
			how: 'ia',
			configurations: {
				message: '',
				loading: 'loading'
			},
			answer: ''
		});
		setTimeout(() => {
			this.prompt = '';
			setTimeout(() => {
				this.textarea.nativeElement.style.height = '3rem';
			}, 100);
			this.contentResponse.nativeElement.scrollTop = this.contentResponse.nativeElement.scrollHeight;
		}, 100);
		this._storageService.removeStorage('zeyoIaData');
		this._zeyoIaService.getChatStream(zeyoChatDto, this.chatHistory).subscribe({
			next: (chunk) => {
				// console.error('TS');
				// console.error('CHUNK');
				// console.error(chunk);
				const json = JSON.parse(chunk);
				// console.error('JSON');
				// console.error(json);
				if (json.message)
					this.chats[this.chats.length - 1].message = this._domSanitizer.bypassSecurityTrustHtml(
						json.message
					);
				if (json.answer) {
					this.chats[this.chats.length - 1].answer = json.answer;
					if (json.answer.chatHistory) this.chatHistory = json.answer.chatHistory;
				}
				setTimeout(() => {
					const contentResponseNativeElement = this.contentResponse.nativeElement;
					contentResponseNativeElement.scrollHeight;
					this.contentResponse.nativeElement.scrollTop = this.contentResponse.nativeElement.scrollHeight;
					setTimeout(() => {
						this._focusEditableDiv();
					}, 100);
				}, 100);
			},
			error: (err) => {
				this.chats[this.chats.length - 1].configurations.loading = 'error';
				this.chats[this.chats.length - 1].configurations.message =
					'Lo sentimos la respuesta no pudo ser generada';
				if (err.message) this.chats[this.chats.length - 1].configurations.message = err.message;
				this.loadings.search = false;
				if (!this.settings.show) this.settings.notification = true;
			},
			complete: () => {
				this.chats[this.chats.length - 1].configurations.loading = 'success';
				this.loadings.search = false;
				if (!this.settings.show) {
					this.settings.notification = true;
				} else {
					const contentResponseNativeElement = this.contentResponse.nativeElement;
					contentResponseNativeElement.scrollHeight;
					setTimeout(() => {
						this.contentResponse.nativeElement.scrollTop = this.contentResponse.nativeElement.scrollHeight;
					}, 100);

					setTimeout(() => {
						this._focusEditableDiv();
					}, 500);
				}
			}
		});
	}

	openChat(): void {
		this.settings.show = true;
		this.settings.notification = false;
		setTimeout(() => {
			const contentResponseNativeElement = this.contentResponse.nativeElement;
			contentResponseNativeElement.scrollHeight;
			this.contentResponse.nativeElement.scrollTop = this.contentResponse.nativeElement.scrollHeight;
			setTimeout(() => {
				this._focusEditableDiv();
			}, 100);
		}, 100);
	}
	cancelSearch(): void {
		this.loadings.search = false;
		this.chats.pop();
	}

	startListening(): void {
		this._speechRecognitionService.startListening();
		this.settings.listening = true;
	}

	stopListening(): void {
		this._speechRecognitionService.stopListening();
		this.settings.listening = false;
		setTimeout(() => {
			if (this.prompt) this.prompt += ' ';
			this.prompt += this._speechRecognitionService.transcript;
		}, 500);
	}

	goTo(chat: Chat): void {
		this._storageService.setStorage('zeyoIaData', chat.answer);
		this._router.navigate([chat.answer.url]);
		this._emittersService.zeyoIa.emit(chat.answer);
		this.settings.show = false;
	}

	private _animateDots(): void {
		let count = 0;
		setInterval(() => {
			count = (count % 4) + 1;
			this.dots = '.'.repeat(count);
		}, 400);
	}

	private _focusEditableDiv(): void {
		const el = this.textarea.nativeElement;
		el.focus();
	}
}
