export enum ARCHIVE_TYPE {
	PDF = 'pdf',
	IMG = 'image'
}

export enum ROLE {
	ADMINISTRATOR = 'admin',
	COLLABORATOR = 'collaborator'
}

export enum GROUP_TYPE {
	INSTANCE = 'instance',
	BUSINESS = 'business'
}

export enum CAMPAIGN_POSITION {
	FOOTER = 'footer',
	OVERLAY = 'overlay',
	SIDEBAR = 'sidebar',
	HEADER = 'header'
}

export enum DINAMIC_DATA_TYPE {
	INPUT = 'input',
	TEXTAREA = 'textarea',
	RADIO = 'radio',
	CHECKBOX = 'checkbox',
	FILE = 'file',
	API = 'api',
	FAO_ZONES = 'fao_zones',
	GEOREFERENCE = 'georeference',
	DECISION = 'decision'
}

export enum CUSTOM_DATA_TYPES {
	SIAP = 'siap',
	NAVIERAS = 'navieras'
}

export enum PAYLOAD_DATA_TYPE {
	VERIFICABLE = 'VerifiableCredential',
	EVENT = 'EventCredential',
	PRODUCT = 'ProductCredential',
	AGGREGATOR = 'aggregator',
	DISAGGREGATOR = 'disaggregator'
}

export enum FEATURE_TYPE {
	SUPPORT = 'support',
	TRANSACTION = 'transaction',
	USER = 'user',
	BUSINESS = 'business',
	WORKSPACE = 'workspace',
	PROCESS = 'process'
}

export enum PAYMENT_METHOD_CODE {
	STRIPE = 'stripe',
	PAYPHONE = 'payphone',
	PAYPAL = 'paypal'
}

export enum TYPE_FILE {
	PDF = 'pdf',
	IMAGE = 'image'
}
