/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SpeechRecognitionService {
	recognition: any;
	isListening = false;
	isDetectedText = false;
	transcript = '';
	constructor() {
		const SpeechRecognition = (window as any).webkitSpeechRecognition || (window as any).SpeechRecognition;
		if (!SpeechRecognition) {
			console.error('¡API de reconocimiento de voz no soportada en este navegador!');
			return;
		}
		this.recognition = new SpeechRecognition();
		this.recognition.lang = 'es-ES';
		this.recognition.continuous = true;
		this.recognition.interimResults = false;

		this.recognition.onresult = (event: any) => {
			this.transcript = event.results[event.results.length - 1][0].transcript.trim();
		};

		this.recognition.onerror = (event: any) => {
			console.error('Error en el reconocimiento de voz:', event.error);
		};
	}

	startListening(): void {
		if (this.isListening) return;
		this.isListening = true;
		this.recognition.start();
	}

	stopListening(): any {
		this.isListening = false;
		this.recognition.stop();
	}
}
