<div
	class="modal fade"
	id="appearance"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
>
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body p-2">
				<div class="fw-bold text-center row m-0">
					<div class="col-auto">
						<span
							(click)="emitters.preferencesEmiter.emit(true)"
							data-bs-dismiss="modal"
							role="button"
							class="text-secondary fw-500"
							>{{ 'miscellaneous.buttons.back' | translate }}</span
						>
					</div>
					<div class="col text-center">
						<span class="fs-20">
							{{ 'modules.account.modals.appearance-modal.title' | translate }}
						</span>
					</div>
					<div class="col-auto">
						<span data-bs-dismiss="modal" role="button" class="text-secondary fw-500">
							{{ 'miscellaneous.buttons.exit' | translate }}
						</span>
					</div>
				</div>
				<div class="hr"></div>
				<div class="content">
					<div
						*ngIf="methodsService.checkRoleUser('admin')"
						#logo
						class="row item mv-1"
						data-bs-toggle="modal"
						data-bs-target="#addLogo"
					>
						<div class="col">
							<div class="header">
								{{ 'modules.account.modals.appearance-modal.logo.title' | translate }}
							</div>
							<div class="sub">
								{{ 'modules.account.modals.appearance-modal.logo.subtitle' | translate }}
							</div>
						</div>
						<div class="col-auto vertical-align text-primary">
							{{ 'miscellaneous.buttons.add' | translate }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-add-logo-modal [selected]="picture"></app-add-logo-modal>
