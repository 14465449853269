export const environment = {
	production: true,
	platform: 'Zeyo',
	branch: 'prod',
	criptoSecretKey: 'yW9^Wtt1DzY1*178CTbPYiSggLsLo^PX0kFJ@rf9*WU2fccl8q',
	apiUrl: 'api/v1/',
	editTraza: false,
	traceReverse: false,
	google: {
		apiTranslate: 'https://translation.googleapis.com/language/translate/v2',
		apiDetect: 'https://translation.googleapis.com/language/translate/v2/detect',
		apiKeyGoogle: 'AIzaSyCMEDzCN4my_91paMnJVROVthkqhX53Lew'
	},
	firebaseConfig: {
		apiKey: 'AIzaSyBQJFYyfYPAs8ebb3wmWIJ5DbqhqkzDHJE',
		authDomain: 'zeyo-traceability-companies.firebaseapp.com',
		projectId: 'zeyo-traceability-companies',
		storageBucket: 'zeyo-traceability-companies.appspot.com',
		messagingSenderId: '1013863792496',
		appId: '1:1013863792496:web:831d54e6444e8e950899b5',
		measurementId: 'G-PL5Q3LVC9E'
	},
	docs_to_sign: 'docs_to_sign_prod',
	hostname: 'https://zeyo-public.s3.amazonaws.com/branding/' + location.hostname,
	kuskhi: {
		publicMerchantId: 'a4a3ce45b5aa491da3754c8e87a50420'
	},
	payment: {
		returnURL: 'https://app.safetrack.cloud/account/paymentReturnUrl',
		cancelURL: 'https://app.safetrack.cloud/account/paymentCancelUrl'
	},
	socialLogin: {
		mainEndpoint:
			'https://auth.safetrack.cloud/realms/zeyo/protocol/openid-connect/auth?client_id=zeyo-cli-social&redirect_uri=',
		providers: {
			google: '&response_type=code&scope=openid&kc_idp_hint=google',
			facebook: '&response_type=code&scope=openid&kc_idp_hint=facebook'
		},
		returnURL: 'https://app.safetrack.cloud/auth/returnUrl'
	}
};
