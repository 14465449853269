import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmittersService } from '../../core/utils/emitters.service';

@Component({
	selector: 'app-name-description-modal',
	templateUrl: './name-description-modal.component.html',
	styleUrls: ['./name-description-modal.component.scss']
})
export class NameDescriptionModalComponent implements OnInit {
	@Input() title = 'Ingresar Información del Proceso';
	@Output() done = new EventEmitter();
	addForm: FormGroup;

	constructor(_emittersService: EmittersService, private _formBuilder: FormBuilder) {
		_emittersService.setNameDescriptionModal.subscribe((res) => this.addForm.patchValue(res));
	}

	ngOnInit(): void {
		this.addForm = this._formBuilder.group({
			name: ['', [Validators.required, Validators.minLength(4)]],
			description: ['', [Validators.required, Validators.minLength(10)]]
		});
	}

	/* 
		Returns the controls of the entire form
		@autor: Carlos Sánchez Mora
		@date: 13-03-2025
	*/
	get a(): any {
		return this.addForm.controls;
	}

	/* 
		Retorna los valores del formulario
		@autor: Carlos Sánchez Mora
		@date: 13-03-2025
	*/
	save(): void {
		this.done.emit(this.addForm.value);
		this.addForm.reset();
	}
}
