import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-qr-modal',
	templateUrl: './qr-modal.component.html',
	styleUrls: ['./qr-modal.component.scss']
})
export class QrModalComponent {
	@Input() url: string;
}
