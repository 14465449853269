import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { AgentComponent } from '../../pages/agent/agent.component';

@Injectable({
	providedIn: 'root'
})
export class InjectorService {
	constructor(private injector: Injector, private appRef: ApplicationRef, private cfr: ComponentFactoryResolver) {}

	appendPersistentComponent(): void {
		const factory = this.cfr.resolveComponentFactory(AgentComponent);
		const componentRef = factory.create(this.injector);
		this.appRef.attachView(componentRef.hostView);
		document.body.appendChild((componentRef.hostView as any).rootNodes[0]);
	}
}
