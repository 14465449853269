<div class="modal fade" id="qr" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">QR</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body text-center">
				<qrcode [qrdata]="this.url" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
			</div>
		</div>
	</div>
</div>
