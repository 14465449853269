import { Injectable } from '@angular/core';
import { servicesConfig } from './services.config';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { InstanceImage, InstanceImagePaginator } from '../interfaces/instance-image';
import { QueryOptions } from '../interfaces/query-options';

const BASE_URL = servicesConfig.api.url + servicesConfig.instanceImage.mainEndpoint;

@Injectable({
	providedIn: 'root'
})
export class InstanceImageService {
	constructor(private _http: HttpClient, private _authService: AuthenticationService) {}

	// GET METHODS
	getImagesByInstanceID(instance_id: string, options?: QueryOptions): Observable<InstanceImagePaginator> {
		let URL = `${BASE_URL}/${instance_id}?`;
		if (options) {
			if (options.boolField && options.boolValue)
				URL += `&boolField=${options.boolField}&boolValue=${options.boolValue}`;
		}
		return this._http.get<InstanceImagePaginator>(URL);
	}
	// END GET METHODS

	// POST METHODS
	createImage(file: File): Observable<InstanceImage> {
		const URL = `${BASE_URL}/${this._authService.currentUser.instance_id}`;
		const formData: any = new FormData();
		formData.append('logo', file);
		return this._http.post<InstanceImage>(URL, formData);
	}
	// END POST METHODS

	// PUT METHODS
	updateImage(imageId: string): Observable<any> {
		const URL = `${BASE_URL}/${imageId}`;
		const body = {
			enabled: true,
			status: true
		};
		return this._http.put<any>(URL, body);
	}
	// END PUT METHODS
}
