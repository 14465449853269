<div class="modal fade" id="confirm" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<h5 class="text-center">
					<strong>{{ title }}</strong>
				</h5>
				<p *ngIf="subtitle">{{ subtitle }}</p>
				<p *ngIf="message">{{ message }}</p>
			</div>
			<div class="modal-footer">
				<button
					*ngIf="otherBtnSuccessText"
					data-bs-dismiss="modal"
					(click)="confirm.emit()"
					class="btn btn-primary"
				>
					{{ otherBtnSuccessText }}
				</button>
				<button data-bs-dismiss="modal" (click)="confirm.emit(true)" class="btn btn-primary">
					{{ btnSuccessText }}
				</button>
				<button *ngIf="btnCancelText" data-bs-dismiss="modal" class="btn btn-outline-primary">
					{{ btnCancelText }}
				</button>
			</div>
		</div>
	</div>
</div>
