import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GROUP_TYPE } from '../enums';
import { CustomProcessCreate } from '../interfaces/custom-process';
import { ProcessPaginator } from '../interfaces/processes';
import { QueryOptions } from '../interfaces/query-options';
import { servicesConfig } from './services.config';

const BASE_URL = servicesConfig.api.url + servicesConfig.workspaceProcess.mainEndpoint;

@Injectable({
	providedIn: 'root'
})
export class WorkspaceProcessService {
	constructor(private _http: HttpClient) {}

	// GET METHODS
	getProcessesByWorkspace(
		groupType: GROUP_TYPE,
		groupCode: string,
		workspaceId: string,
		options?: QueryOptions
	): Observable<ProcessPaginator> {
		let URL = `${BASE_URL}/${servicesConfig.workspaceProcess.routes.byWorkspace}${groupType}/${groupCode}/${workspaceId}?`;
		if (options) {
			if (options.limit && options.offset) URL += `limit=${options.limit}&offset=${options.offset}&`;
			if (options.relations) URL += `relations=${options.relations}`;
		}
		return this._http.get<ProcessPaginator>(URL);
	}

	getNextCompaniesEvent(
		groupType: GROUP_TYPE,
		groupCode: string,
		workspaceProcessId: string,
		typeInstanceComponentId: string
	): Observable<any> {
		const URL = `${BASE_URL}/${servicesConfig.workspaceProcess.routes.nextCompaniesEvent}/${groupType}/${groupCode}/${workspaceProcessId}/${typeInstanceComponentId}`;
		return this._http.get<any>(URL);
	}

	// END GET METHODS
	postWorkspacesWhitCustomProcess(body: CustomProcessCreate): Observable<any> {
		const URL = `${BASE_URL}`;
		return this._http.post(URL, body);
	}
	deletetWorkspacesByCustomProcess(groupType: string, groupCode: string, id: string): Observable<any> {
		const URL = `${BASE_URL}/${groupType}/${groupCode}/${id}`;
		return this._http.delete(URL);
	}
}
