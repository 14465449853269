import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() message: string;
	@Input() btnCancelText: string;
	@Input() btnSuccessText: string;
	@Input() otherBtnSuccessText: string;

	@Output() confirm = new EventEmitter();
}
