/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InjectorService } from './core/services/injector.service';
import { SpeechRecognitionService } from './core/services/speech-recognition.service';
import { AgentComponent } from './pages/agent/agent.component';

export function initPersistentComponent(injectorService: InjectorService) {
	return () => injectorService.appendPersistentComponent();
}

@NgModule({
	declarations: [AgentComponent],
	imports: [CommonModule, FormsModule],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initPersistentComponent,
			deps: [InjectorService],
			multi: true
		},
		SpeechRecognitionService
	]
})
export class AgentZeyoIaModule {}
