import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum toastTypes {
	success,
	warning,
	error
}

export interface ToastData {
	title: string;
	message: string;
	show?: boolean;
	type?: toastTypes;
	progressWidth?: string;
	timeout?: number;
}

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	data!: ToastData;
	public open = new Subject<ToastData>();

	// Instantiate and initialize the toast
	// @autor: Carlos Sánchez Mora
	// @date: 29-07-2022
	initiate(data: ToastData): void {
		if (!data.timeout) {
			data.timeout = 3500;
		}
		this.data = { ...data, show: true, progressWidth: '100%' };
		this.open.next(this.data);
	}

	// Close the toast
	// @autor: Carlos Sánchez Mora
	// @date: 29-07-2022
	hide(): void {
		this.data = { ...this.data, show: false };
		this.open.next(this.data);
	}
}
