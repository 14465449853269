<div
	class="modal fade"
	id="preferences"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
>
	<div class="modal-dialog modal-xl modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body" [ngClass]="{ 'p-2': screen.width > 900 }">
				<div class="fw-bold text-center">
					<span class="fs-20">{{ 'modules.account.modals.preferences-modal.title' | translate }}</span>
					<span data-bs-dismiss="modal" role="button" class="text-secondary fw-500 float-right">{{
						'miscellaneous.buttons.exit' | translate
					}}</span>
				</div>
				<div class="hr"></div>
				<div class="row m-0">
					<div class="col-lg-6 mb-1">
						<div class="item row" data-bs-toggle="modal" data-bs-target="#appearance">
							<div class="col-auto vertical-align">
								<img src="./assets/svgs/preferences/appearance.svg" alt="" />
							</div>
							<div class="col text-center">
								<div class="header">
									{{ 'modules.account.modals.preferences-modal.appearance.title' | translate }}
								</div>
								<div class="sub">
									{{ 'modules.account.modals.preferences-modal.appearance.subtitle' | translate }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<button #appearance data-bs-toggle="modal" data-bs-target="#appearance" style="display: none"></button>
<app-appearance-modal></app-appearance-modal>
