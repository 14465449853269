import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ROLE } from '../enums';
import { CurrentUser } from '../interfaces/auth';
import { JwtService } from '../jwt/jwt.service';
import { AuthenticationService } from '../services/authentication.service';
import { MethodsService } from '../utils/methods.service';
import { StorageService } from '../utils/storage.service';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceGuard implements CanActivate {
	first = true;

	constructor(
		private _router: Router,
		private _storage: StorageService,
		private _jwt: JwtService,
		private _methodsService: MethodsService,
		private _authenticationService: AuthenticationService
	) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const currentUser = this._storage.getStorage(environment.platform);
		const workspace = this._storage.getStorage('workspace');
		if (currentUser) {
			const jwt: CurrentUser = this._storage.getDataStorage(environment.platform);
			jwt.user = this._jwt.decodeToken(jwt.access_token);
			if (this._methodsService.haveBothRoles()) {
				if (this._authenticationService.selectedRole) return true;
				this._router.navigate(['access'], { queryParams: { returnUrl: state.url } });
				return false;
			}
			if (this._methodsService.isCollaborator()) {
				this._storage.setStorage('role', 'collaborator');
				this._authenticationService.selectedRole = ROLE.COLLABORATOR;
				if (workspace) {
					const data = this._storage.getDataStorage('workspace');
					this._authenticationService.workspace = data;
					this._authenticationService.currentUser.instance_id = data.instanceId;
					return true;
				}
				this._router.navigate(['workspaces'], { queryParams: { returnUrl: state.url } });
				return false;
			}
			this._authenticationService.selectedRole = ROLE.ADMINISTRATOR;
			this._storage.setStorage('role', 'admin');
			return true;
		}
		this._router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
		return false;
	}
}
