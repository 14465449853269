import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { features_plan } from '../../core/types';
import { EmittersService } from '../../core/utils/emitters.service';

export interface BuyPlan {
	opt: features_plan;
	necessary: number;
	available: number;
}

@Component({
	selector: 'app-buy-plan-modal',
	templateUrl: './buy-plan-modal.component.html',
	styleUrls: ['./buy-plan-modal.component.scss']
})
export class BuyPlanModalComponent {
	buyPlanParams!: BuyPlan;

	constructor(
		_emittersService: EmittersService,
		private _router: Router,
		private _translateService: TranslateService
	) {
		_emittersService.buyPlan.subscribe((res) => (this.buyPlanParams = res));
	}

	go(): void {
		let url = this._router.url;
		this._router.navigate(['/account/plans'], { queryParams: { returnUrl: url } });
	}

	transformText(): string {
		if (this.buyPlanParams.opt) {
			let text: any = this.buyPlanParams.opt;
			if (this._translateService.currentLang === 'en') {
				if (this.buyPlanParams.opt === 'empresas') text = 'companies';
				if (this.buyPlanParams.opt === 'espacios') text = 'workspaces';
				if (this.buyPlanParams.opt === 'procesos') text = 'process';
				if (this.buyPlanParams.opt === 'transacciones') text = 'transactions';
				if (this.buyPlanParams.opt === 'usuarios') text = 'users';
			}
			return text;
		}
		return '';
	}
}
