import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministratorGuard } from './core/guards/administrator.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { CollaboratorGuard } from './core/guards/collaborator.guard';
import { WorkspaceGuard } from './core/guards/workspace.guard';
import { LayoutComponent } from './layout/layout/layout.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
	{
		path: 'faq',
		loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule)
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
	},
	{
		path: 'timeline',
		loadChildren: () => import('./modules/timeline/timeline.module').then((m) => m.TimelineModule)
	},
	{
		path: 'timeline-track',
		loadChildren: () => import('./modules/timeline-track/timeline-track.module').then((m) => m.TimelineTrackModule)
	},
	{
		path: 'legal',
		loadChildren: () => import('./modules/legal/legal.module').then((m) => m.LegalModule)
	},
	{
		path: 'app',
		loadChildren: () => import('./app/app.module').then((m) => m.AppModule)
	},
	{
		path: 'workspaces',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/workspaces/workspaces.module').then((m) => m.WorkspacesModule)
	},
	{
		path: 'access',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/access/access.module').then((m) => m.AccessModule)
	},
	{
		path: '',
		canActivate: [AuthGuard, WorkspaceGuard],
		component: LayoutComponent,
		children: [
			{
				canActivate: [],
				path: 'dashboard',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'operations',
				loadChildren: () => import('./modules/operations/operations.module').then((m) => m.OperationsModule)
			},
			{
				canActivate: [CollaboratorGuard],
				path: 'processes',
				loadChildren: () => import('./modules/processes/processes.module').then((m) => m.ProcessesModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'spaces',
				loadChildren: () => import('./modules/spaces/spaces.module').then((m) => m.SpacesModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'products',
				loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'events',
				loadChildren: () => import('./modules/events/events.module').then((m) => m.EventsModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'products',
				loadChildren: () => import('./modules/products/products.module').then((m) => m.ProductsModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'administrator',
				loadChildren: () =>
					import('./modules/administrator/administrator.module').then((m) => m.AdministratorModule)
			},
			{
				path: 'traces',
				loadChildren: () => import('./modules/traces/traces.module').then((m) => m.TracesModule)
			},
			{
				canActivate: [AdministratorGuard],
				path: 'account',
				loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule)
			}
		]
	},
	{ path: '**', component: NotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
