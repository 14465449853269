<div
	(mouseleave)="countDown()"
	class="toast_wrapper"
	[ngClass]="{
		success: toastService.data && toastService.data.type === 0,
		warning: toastService.data && toastService.data.type === 1
	}"
	[@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
>
	<div class="inner_wrapper">
		<div class="row m-0" *ngIf="toastService.data">
			<div class="col-auto vertical-align">
				<em
					*ngIf="toastService.data && toastService.data.type === 0"
					class="bi bi-check-circle text-success"
				></em>
				<em
					*ngIf="toastService.data && toastService.data.type === 1"
					class="bi bi-exclamation-triangle-fill text-warning"
				></em>
				<em *ngIf="toastService.data && toastService.data.type === 2" class="bi bi-x-circle text-danger"></em>
			</div>
			<div class="col">
				<h5 class="toast_title">
					{{ toastService.data.title }}
				</h5>
				<div class="toast_text">
					{{ toastService.data.message }}
				</div>
			</div>
			<div class="col-auto vertical-align toast_text close ml-1" (click)="close()">Cerrar</div>
		</div>
	</div>
</div>
