import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private _auth: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this._auth.currentUser && this._auth.currentUser.access_token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this._auth.currentUser.access_token}`
				}
			});
		}
		return next.handle(request);
	}
}
