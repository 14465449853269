import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { JwtService } from '../jwt/jwt.service';
import { AuthenticationService } from '../services/authentication.service';
import { MethodsService } from '../utils/methods.service';
import { StorageService } from '../utils/storage.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private _router: Router,
		private _storage: StorageService,
		private _auth: AuthenticationService,
		private _methodsService: MethodsService,
		private _jwt: JwtService
	) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const currentUser = this._storage.getStorage(environment.platform);
		const workspace = this._storage.getDataStorage('workspace');
		if (currentUser) {
			const jwt = this._storage.getDataStorage(environment.platform);
			jwt.user = this._jwt.decodeToken(jwt.access_token);
			// this._authenticationService.currentUser.user.groups

			if (workspace) {
				jwt.instance_id = workspace.instanceId;
				const data = this._storage.getDataStorage('workspace');
				this._auth.workspace = data;
			}
			this._auth.currentUser = jwt;
			this._auth.selectedRole = this._storage.getDataStorage('role');
			this._auth.expiredTokenInterval(this._auth.currentUser.access_token);
			return true;
		}
		this._router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
		return false;
	}
}
