<div class="modal fade" id="buyPlan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body p-2 text-center" *ngIf="buyPlanParams">
				<h4><strong>¡No se puede completar la acción!</strong></h4>
				<div class="my-3">
					No cuentas con <strong>{{ transformText() | uppercase }} </strong> suficientes
				</div>
				<div class="row m-0 my-4">
					<div class="col pl-0 text-center">
						<div>Necesarias</div>
						<h2>
							<strong>{{ buyPlanParams.necessary }}</strong>
						</h2>
					</div>
					<div class="col pr-0 text-center">
						<div>Disponibles</div>
						<h2 class="text-danger">
							<strong>{{ buyPlanParams.available }}</strong>
						</h2>
					</div>
				</div>
				<button
					data-bs-dismiss="modal"
					(click)="go()"
					routerLinkActive="router-link-active"
					class="btn btn-primary text-white"
				>
					Conseguir más
				</button>
			</div>
		</div>
	</div>
</div>
