<div
	class="modal fade"
	id="nameDescriptionModal"
	tabindex="-1"
	role="dialog"
	aria-labelledby="exampleModalLabel"
	aria-hidden="true"
>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="text-center fw-bold">{{ title }}</div>

				<form [formGroup]="addForm">
					<div class="mb-2">
						<label for="">Nombre</label>
						<input
							formControlName="name"
							class="form-control"
							placeholder="Ingresa el nombre"
							[ngClass]="{ 'is-invalid': a.name.touched && a.name.errors }"
						/>
						<div *ngIf="a.name.touched && a.name.errors" class="invalid-feedback">
							<div *ngIf="a.name.errors.required">Campo requerido</div>
							<div *ngIf="a.name.errors.minlength">
								es necesario por lo menos {{ a.name.errors.minlength.requiredLength }} caracteres
							</div>
						</div>
					</div>
					<div>
						<label for="">Descripción</label>
						<textarea
							formControlName="description"
							class="form-control"
							placeholder="Ingresa la descripción"
							rows="6"
							[ngClass]="{ 'is-invalid': a.description.touched && a.description.errors }"
						></textarea>

						<div *ngIf="a.description.touched && a.description.errors" class="invalid-feedback">
							<div *ngIf="a.description.errors.required">Campo requerido</div>
							<div *ngIf="a.description.errors.minlength">
								Es necesario por lo menos {{ a.description.errors.minlength.requiredLength }} caracteres
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="modal-footer">
				<button data-bs-dismiss="modal" class="btn btn-outline-primary">
					{{ 'miscellaneous.buttons.return' | translate }}
				</button>
				<button
					(click)="save()"
					[disabled]="addForm.invalid"
					data-bs-dismiss="modal"
					class="ml-1 btn btn-primary"
				>
					Aceptar
				</button>
			</div>
		</div>
	</div>
</div>
