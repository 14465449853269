import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageService } from '../utils/storage.service';

@Injectable({
	providedIn: 'root'
})
export class IsAuthGuard implements CanActivate {
	constructor(private _location: Location, private _storage: StorageService) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const currentUser = this._storage.getStorage(environment.platform);
		if (currentUser) {
			this._location.back();
			return false;
		}
		return true;
	}
}
