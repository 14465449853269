/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { LayoutModule } from './layout/layout.module';

import { AppComponent } from './app.component';
import { AlertInputComponent } from './shared/alerts/alert-input/alert-input.component';
import { AlertComponent } from './shared/alerts/alert/alert.component';
import { ToastComponent } from './shared/toasts/toast/toast.component';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';

import { registerLocaleData } from '@angular/common';
import LocaleEs from '@angular/common/locales/es';
import { LoadingComponent } from './shared/loadings/loading/loading.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// FIREBASE
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from '../environments/environment.prod';
import { AgentZeyoIaModule } from './agent-zeyo-ia/agent-zeyo-ia.module';
import { ModalsModule } from './modals/modals.module';
import { NotFoundComponent } from './shared/not-found/not-found.component';

registerLocaleData(LocaleEs, 'es');
@NgModule({
	declarations: [
		AppComponent,
		ToastComponent,
		AlertInputComponent,
		LoadingComponent,
		AlertComponent,
		NotFoundComponent
	],
	imports: [
		LayoutModule,
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ModalsModule,
		ReactiveFormsModule,
		AgentZeyoIaModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient]
			}
		}),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideFirestore(() => getFirestore()),
		provideStorage(() => getStorage()),
		provideAuth(() => {
			return getAuth();
		})
	],
	providers: [
		HttpClient,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'es' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}

// AoT requires an exported function for factories
export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
