<div
	class="content-alert fullscreen vertical-align"
	*ngIf="alertService.data"
	[@openClose]="alertService.data && alertService.data.show ? 'open' : 'closed'"
>
	<div *ngIf="alertService.data.title === 'Renovar Plan' && divVisible" class="overlay" (click)="toggleDiv()">
		<div>
			<div class="contentPlan">
				<section class="row p-3">
					<i
						class="bi bi-x-lg text-right text-white close fw-bold"
						style="cursor: pointer"
						data-bs-dismiss="modal"
						role="button"
						(click)="close(false)"
					></i>
					<div class="text-white text-center title-plan fw-bold fs-20">{{ 'shared.title' | translate }}</div>
					<div class="row mt-2">
						<div class="col-lg-9">
							<div class="row">
								<div class="fs-18 fw-bold text-white">{{ 'shared.line' | translate }}</div>
							</div>
							<div class="row">
								<div class="fs-18 fw-300 text-white mt-1">{{ 'shared.second-line' | translate }}</div>
							</div>
							<div class="row">
								<div class="fs-18 fw-300 text-white mt-1">{{ 'shared.third-line' | translate }}</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="row text-right">
								<div class="imagen text-right">
									<img src="../../../../assets/imgs/fonds/group.png" width="200" />
								</div>
							</div>
						</div>
					</div>
					<div class="text-center mt-3">
						<button class="btn btn-primary text-white" (click)="close(true); goToPlanes()">
							Ver planes
						</button>
					</div>
				</section>
			</div>
		</div>
	</div>

	<div *ngIf="alertService.data.title !== 'Renovar Plan'" class="content text-center">
		<i
			class="bi bi-x-lg text-right mt-1 ml-30 mr-1"
			style="cursor: pointer"
			data-bs-dismiss="modal"
			role="button"
			(click)="close(false)"
		></i>
		<div class="fs-26 f-bold">{{ alertService.data.title }}</div>
		<div>{{ alertService.data.subtitle }}</div>
		<div *ngIf="alertService.data.icon">
			<em *ngIf="alertService.data.type === 0" class="bi icon text-primary" [class]="alertService.data.icon"></em>
			<em *ngIf="alertService.data.type === 1" class="bi icon text-warning" [class]="alertService.data.icon"></em>
			<em *ngIf="alertService.data.type === 2" class="bi icon text-danger" [class]="alertService.data.icon"></em>
		</div>
		<div class="text-muted">{{ alertService.data.message }}</div>
		<br />
		<button
			(click)="close(true)"
			class="btn"
			[ngClass]="{
				'btn-primary': alertService.data.type === 0,
				'btn-outline-warning': alertService.data.type === 1,
				'btn-outline-danger': alertService.data.type === 2
			}"
		>
			{{ alertService.data.confirmButtonText }}
		</button>
		<br />
		<br />
		<button *ngIf="alertService.data.cancelButtonText" (click)="close(false)" class="btn btn-outline-primary">
			<i class="fs-14 bi bi-arrow-left-short"></i>
			{{ alertService.data.cancelButtonText }}
		</button>
	</div>
</div>
