import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private _authService: AuthenticationService,
		private _translateService: TranslateService,
		private _router: Router
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				const error = err.error;
				if (!error.description) {
					let description = 'Nos encontramos en mantenimiento, por favor vuelve a intentarlo en unos minutos';
					if (this._translateService.currentLang === 'en')
						description = 'We are currently undergoing maintenance, please try again in a few minutes.';
					error.description = description;
				}
				if (error.description === 'no found pubkey user') {
					error.description = 'El usuario todavía no se registra en la App';
					if (this._translateService.currentLang === 'en')
						error.description = 'The user is not registered in the App yet';
				}
				switch (err.status) {
					case 404:
						// if (error.renewPlan) this._router.navigate(['account'], { queryParams: { renew: true } });
						break;
					case 401:
						this._authService.signout();
						break;
					default:
						break;
				}
				return throwError(() => error);
			})
		);
	}
}
