<div
	class="content-alert fullscreen vertical-align"
	*ngIf="loadingService.data"
	[@openClose]="loadingService.data && loadingService.data._show ? 'open' : 'closed'"
>
	<div class="content text-center">
		<p *ngIf="loadingService.data.header" class="fs-26 f-bold">{{ loadingService.data.header }}</p>
		<div class="spinner-border text-primary" role="status"></div>
		<p (click)="close()" *ngIf="loadingService.data.button" role="button" class="fs-16 text-secondary">
			{{ loadingService.data.button }}
		</p>
	</div>
</div>
