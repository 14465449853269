import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DINAMIC_DATA_TYPE, PAYLOAD_DATA_TYPE, TYPE_FILE } from '../enums';
import { Field } from '../interfaces/data';
import { TimelineTrace } from '../interfaces/trace';
import { AuthenticationService } from '../services/authentication.service';
import { StaticElementsService } from './static-elements.service';

@Injectable({
	providedIn: 'root'
})
export class MethodsService {
	constructor(
		private _authenticationService: AuthenticationService,
		private _staticElementsService: StaticElementsService
	) {}

	isCollaborator(): boolean {
		let colaborator = false;
		const find = this._authenticationService.currentUser.user.realm_access.roles.find(
			(res) => res === 'collaborator'
		);
		if (find) colaborator = true;
		// for (const iterator of this._authenticationService.currentUser.user.realm_access.roles) {
		// 	const split = iterator.split('/');
		// 	if (split.length > 2) colaborator = true;
		// }
		return colaborator;
	}

	copyLink(data: any): void {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = data;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
	}

	checkRoleUser(role: string): boolean {
		if (this._authenticationService.currentUser) {
			return this._authenticationService.selectedRole === role;
		}
		return false;
	}

	haveBothRoles(): boolean {
		const colaborator = this.isCollaborator();
		let admin = false;
		const find = this._authenticationService.currentUser.user.realm_access.roles.find((res) => res === 'admin');
		if (find) admin = true;
		// if (this._authenticationService.currentUser) {
		// 	for (const iterator of this._authenticationService.currentUser.user.groups) {
		// 		const split = iterator.split('/');
		// 		if (split.length === 2) admin = true;
		// 		if (split.length > 2) colaborator = true;
		// 	}
		// }
		return admin && colaborator;
	}

	downloadPdfBase64(base64String: string, fileName: string): void {
		const source = `data:application/pdf;base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}.pdf`;
		link.click();
	}
	downloadPdfBase64Edit(base64String: string, fileName: string): any {
		const source = `data:application/pdf;base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}.pdf`;
		return link.href;
	}
	downloadImageBase64Edit(base64String: string, fileName: string): any {
		const source = `data:image/png;base64,${base64String}`;
		const link = document.createElement('a');
		link.href = source;
		link.download = `${fileName}`;
		return link.href;
	}

	/* 
		Return te string of image
		@autor: Carlos Sánchez Mora
		@date: 07-03-2023
	*/
	generateImage(field: Field): string {
		if (field.localization.length > 0) return field.localization[0].urlS3;
		return 'data:image/png;base64,' + field.value;
	}

	/* 
		Return te string of image
		@autor: Carlos Sánchez Mora
		@date: 07-03-2023
	*/
	generatePdf(field: Field): string {
		if (field.localization.length > 0) return field.localization[0].urlS3;
		return field.value;
	}

	/* 
		Return te string of image
		@autor: Carlos Sánchez Mora
		@date: 07-03-2023
	*/
	isPrivateFile(field: Field): boolean {
		return field.localization.length === 0;
	}

	/* 
		Return the name of dataType
		@autor: Carlos Sánchez Mora
		@date: 10-03-2023
	*/
	getNameOfDataType(dataType: string): string {
		if (dataType) {
			const html = dataType.split(' ')[0];
			let type = dataType.split(' ')[1];
			if (type) type = type.substr(1, type.length - 2);
			if (
				html === DINAMIC_DATA_TYPE.INPUT ||
				html === DINAMIC_DATA_TYPE.FAO_ZONES ||
				html === DINAMIC_DATA_TYPE.TEXTAREA
			) {
				{
					for (const iterator of this._staticElementsService.dataType) {
						if (iterator.elementHTML === html) return iterator.label;
					}
				}
			}
			if (html === DINAMIC_DATA_TYPE.RADIO) return 'Opción Única';
			if (html === DINAMIC_DATA_TYPE.CHECKBOX) return 'Opción Multiple';
			if (html === DINAMIC_DATA_TYPE.FILE) {
				if (type === 'image') return 'Archivo IMAGEN';
				if (type === 'pdf') return 'Archivo PDF';
				if (type === 'xlsx') return 'Archivo EXCEL';
			}
			if (html === DINAMIC_DATA_TYPE.API) {
				if (type === 'navieras') return 'Geo Navieras';
				if (type === 'siap') return 'SIAP';
			}
		}
		return dataType;
	}

	/* 
		Return the name of the actual branch
		@autor: Carlos Sánchez Mora
		@date: 27-04-2023
	*/
	currentBranch(): string {
		return environment.branch;
	}

	/* 
		Scrolling to element section if exists
		@autor: Carlos Sánchez Mora
		@date: 08-05-2023
	*/
	scrollToElementById(id: any): void {
		const element = document.getElementById(id);
		if (element) element.scrollIntoView({ behavior: 'smooth' });
	}

	/* 
		Open in a new window the url
		@autor: Carlos Sánchez Mora
		@date: 05-06-2023
	*/
	openUrl(url: string): void {
		window.open(url, '_blank');
	}

	/* 
		Check if the element is a file
		@autor: Carlos Sánchez Mora
		@date: 12-06-2023
	*/
	isFile(field: Field): boolean {
		const html = field.metadata.dataType.split(' ')[0];
		return html === DINAMIC_DATA_TYPE.FILE;
	}

	/* 
		Check if the element is a PDF file
		@autor: Carlos Sánchez Mora
		@date: 12-06-2023
	*/
	isPDF(field: Field): boolean {
		const html = field.metadata.dataType.split(' ');
		if (html[0] === DINAMIC_DATA_TYPE.FILE) return html[1] === '(pdf)';
		return false;
	}

	/* 
		Check if the element is a Date
		@autor: Carlos Sánchez Mora
		@date: 12-06-2023
	*/
	isDate(field: Field): boolean {
		// if ()
		// console.log(field.metadata.dataType);
		// const html = field.metadata.dataType.split(' ')[0];
		// let html_type = field.jsonWrite.dataType.replace(html, '');
		// if (html_type) {
		// 	html_type = html_type.substr(2, html_type.length - 3);
		// 	return html_type === 'date';
		// }
		return false;
	}

	/* 
		Check if the element is a image file
		@autor: Carlos Sánchez Mora
		@date: 12-06-2023
	*/
	isImage(field: Field): boolean {
		const html = field.metadata.dataType.split(' ');
		if (html[0] === DINAMIC_DATA_TYPE.FILE) return html[1] === '(image)';
		return false;
	}

	/* 
		Check if the element is a product
		@autor: Carlos Sánchez Mora
		@date: 12-06-2023
	*/
	isProduct(field: TimelineTrace): boolean {
		return field.fields.type === PAYLOAD_DATA_TYPE.PRODUCT || field.fields.type === 'product';
	}

	/* 
		Generate a new file if the file is private
		@autor: Carlos Sánchez Mora
		@date: 15-06-2023
	*/
	async generateFileToPrivate(field: Field, typeFile: TYPE_FILE): Promise<File> {
		let name = field.jsonWrite.property;
		let link: string = this.downloadPdfBase64Edit(field.jsonWrite.property, name);
		let base64;
		let type = 'image';
		if (typeFile === TYPE_FILE.PDF) {
			base64 = link.replace('data:application/pdf;base64,', '');
			type = 'application/pdf';
		} else {
			base64 = link.replace('data:image/png;base64,', '');
			base64 = base64.replace('data:image/jpg;base64,', '');
		}
		const byteArray = new Uint8Array(
			atob(base64)
				.split('')
				.map((char) => char.charCodeAt(0))
		);
		const files = new Blob([byteArray], { type });
		const fileUrl = URL.createObjectURL(files);
		let content = fileUrl;
		const blob = await fetch(content).then((res) => res.blob());
		return new File([blob], name, {
			type
		});
	}

	/* 
		Generate a new pdf file if the file is public
		@autor: Carlos Sánchez Mora
		@date: 15-06-2023
	*/
	async generateFileToPublicPDF(field: Field): Promise<File> {
		let content = field.localization[0].urlS3 || field.localization[0].ipfs;
		let headers = new Headers();
		headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
		const blob = await fetch(content, { headers }).then((res) => res.blob());
		return new File([blob], field.value, {
			type: 'application/pdf'
		});
	}

	/* 
		Generate a new pdf file if the file is public
		@autor: Carlos Sánchez Mora
		@date: 15-06-2023
	*/
	async generateFileToPublicImage(field: Field): Promise<File> {
		let url = field.localization[0].urlS3 || field.localization[0].ipfs;
		return await this.getBase64ImageFromURL(url).then(async (data: any) => {
			data = 'data:image/jpg;base64,' + data;
			let link2: string = this.downloadImageBase64Edit(data, field.jsonWrite.property);
			let base64 = link2.replace('data:image/jpg;base64,', '');
			base64 = base64.replace('data:image/png;base64,', '');
			const byteArray = new Uint8Array(
				atob(base64)
					.split('')
					.map((char) => char.charCodeAt(0))
			);
			const files = new Blob([byteArray], { type: 'image' });
			const fileUrl = URL.createObjectURL(files);

			let content = fileUrl;
			const blob = await fetch(content).then((res) => res.blob());
			return new File([blob], field.jsonWrite.property, {
				type: 'image'
			});
		});
	}

	/* 
		generate base 63 from url
		@autor: Carlos Sánchez Mora
		@date: 15-06-2023
	*/
	async getBase64ImageFromURL(url: string): Promise<any> {
		return Observable.create((observer: Observer<string>) => {
			let img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = url;
			img.src = url + '?not-from-cache-please';
			if (!img.complete) {
				img.onload = (): any => {
					observer.next(this.getBase64Image(img));
					observer.complete();
				};
				img.onerror = (err): any => {
					observer.error(err);
				};
			} else {
				observer.next(this.getBase64Image(img));
				observer.complete();
			}
		}).toPromise();
	}

	/* 
		Return base64 image
		@autor: Carlos Sánchez Mora
		@date: 15-06-2023
	*/
	getBase64Image(img: any): any {
		var canvas = document.createElement('canvas');
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0);
		var dataURL = canvas.toDataURL('image/png');
		return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
	}

	/* 
		Return string to number of limit
		@autor: Carlos Sánchez Mora
		@date: 19-06-2023
	*/
	limitString(text: string, limit: number): string {
		const label = text.slice(0, limit) + '...';
		return label;
	}

	/* 
		Refactor data
		@autor: Carlos Sánchez Mora
		@date: 15-06-2023
	*/
	refactorToExport(event: TimelineTrace): any {
		const fields = [];
		for (const field of event.fields.fields) {
			const html = field.metadata.dataType.split(' ')[0];
			if (html !== DINAMIC_DATA_TYPE.FILE) fields.push(field);
		}
		const data = [];
		let count = 1;
		let rows: any = [];
		for (const field of fields) {
			rows.push(field);
			count++;
			if (count > 2) {
				count = 1;
				data.push({
					row: rows
				});
				rows = [];
			}
		}
		if (fields.length % 3 !== 0)
			data.push({
				row: rows
			});
		return data;
	}

	/* 
		Return the number of the space for columns to expotrt pdf
		@autor: Carlos Sánchez Mora
		@date: 22-06-2023
	*/
	calcHeightColumExport(num: number): number {
		let response = 20;
		const count = 10;
		const lines = Math.trunc(num / 44) + 1;
		return response + count * lines;
	}

	/* 
		Return the number of the space for columns to expotrt pdf
		@autor: Carlos Sánchez Mora
		@date: 22-06-2023
	*/
	calcHeightTitleExport(num: number): number {
		const count = 5;
		const lines = Math.trunc(num / 40) + 1;
		return count * lines;
	}

	/* 
		Verifica si el email ingresado coincide con el email de la cuenta
		@autor: Carlos Sánchez Mora
		@date: 28-08-2024
	*/
	matchEmailWithAccount(email: string): boolean {
		return this._authenticationService.currentUser.user.email === email;
	}
}
