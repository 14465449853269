import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Branding } from '../interfaces/branding';

@Injectable({
	providedIn: 'root'
})
export class BrandingService {
	logoPrincipal: string;
	logoWhite: string;
	logoAuth: string;
	error: boolean = false;

	constructor(private _http: HttpClient) {
		this.getBranding();
	}

	// GET METHODS
	getBranding(): void {
		this._http.get<Branding>(environment.hostname + '/data.json').subscribe({
			next: (res) => {
				environment.platform = res.title;
				this.logoPrincipal = environment.hostname + '/' + res.logoPrincipal;
				this.logoWhite = environment.hostname + '/' + res.logoWhite;
				this.logoAuth = environment.hostname + '/' + res.logoAuth;
				if (res.logoAuth === 'undefined' || res.logoAuth === undefined) {
					this.error = true;
				}
			},
			error: (err) => {
				console.error(err);
			}
		});
	}
	// END GET METHODS
}
