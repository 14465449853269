import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { QRCodeModule } from 'angularx-qrcode';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AddLogoModalComponent } from './add-logo-modal/add-logo-modal.component';
import { AppearanceModalComponent } from './appearance-modal/appearance-modal.component';
import { ArchivesModalComponent } from './archives-modal/archives-modal.component';
import { BuyPlanModalComponent } from './buy-plan-modal/buy-plan-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DropzoneModalComponent } from './dropzone-modal/dropzone-modal.component';
import { NameDescriptionModalComponent } from './name-description-modal/name-description-modal.component';
import { PreferencesModalComponent } from './preferences-modal/preferences-modal.component';
import { QrModalComponent } from './qr-modal/qr-modal.component';
import { ViewAllImagesModalComponent } from './view-all-images-modal/view-all-images-modal.component';
import { PictureModalComponent } from './view/picture-modal/picture-modal.component';

@NgModule({
	declarations: [
		DropzoneModalComponent,
		PictureModalComponent,
		ArchivesModalComponent,
		QrModalComponent,
		ConfirmModalComponent,
		AppearanceModalComponent,
		AddLogoModalComponent,
		PreferencesModalComponent,
		ViewAllImagesModalComponent,
		BuyPlanModalComponent,
		NameDescriptionModalComponent
	],
	imports: [CommonModule, NgxDropzoneModule, TranslateModule, QRCodeModule, FormsModule, ReactiveFormsModule],
	exports: [
		DropzoneModalComponent,
		PictureModalComponent,
		ArchivesModalComponent,
		QrModalComponent,
		ConfirmModalComponent,
		AppearanceModalComponent,
		AddLogoModalComponent,
		PreferencesModalComponent,
		ViewAllImagesModalComponent,
		BuyPlanModalComponent,
		NameDescriptionModalComponent
	]
})
export class ModalsModule {}
