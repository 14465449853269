import { Component } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AlertInputService } from './alert-input.service';

@Component({
	selector: 'app-alert-input',
	templateUrl: './alert-input.component.html',
	styleUrls: ['./alert-input.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'closed',
				style({
					visibility: 'hidden'
				})
			),

			transition('open <=> closed', [animate('0.1s ease-in-out')])
		])
	]
})
export class AlertInputComponent {
	text: string = '';

	// Alert subscription
	// @autor: Carlos Sánchez Mora
	// @date: 02-08-2022
	constructor(public alertService: AlertInputService) {
		this.alertService.open.subscribe(() => {});
	}

	// Close the alert
	// @autor: Carlos Sánchez Mora
	// @date: 02-08-2022
	close(): any {
		this.alertService.hide(this.text);
		this.text = '';
		return;
	}
}
