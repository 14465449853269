import { EventEmitter, Injectable } from '@angular/core';
import { BuyPlan } from '../../modals/buy-plan-modal/buy-plan-modal.component';

@Injectable({
	providedIn: 'root'
})
export class EmittersService {
	logoEmiter = new EventEmitter();
	refreshPlan = new EventEmitter();
	preferencesEmiter = new EventEmitter();
	appearanceEmiter = new EventEmitter();
	addLogoEmiter = new EventEmitter();
	languajeEmiter = new EventEmitter();
	processEmiter = new EventEmitter();
	addUserCompany = new EventEmitter();
	chooseFlowModal = new EventEmitter();
	feedbackResumenModal = new EventEmitter();
	products = new EventEmitter();
	invitateExternalCompany = new EventEmitter();
	processComponent = new EventEmitter();
	findCodeModal = new EventEmitter();
	configurationFieldModal = new EventEmitter();
	createRole = new EventEmitter();
	createRoleOrigin = new EventEmitter();

	refactorListReadingRole = new EventEmitter();

	loadingService = new EventEmitter();

	georeferenceModal = new EventEmitter();
	productModal = new EventEmitter();

	account = new EventEmitter();

	archivesModal = new EventEmitter();
	buyPlan = new EventEmitter<BuyPlan>();

	data = new EventEmitter();

	roleModal = new EventEmitter();
}
