import { Component, ElementRef, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastService } from './toast.service';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	animations: [
		trigger('openClose', [
			state(
				'closed',
				style({
					visibility: 'hidden',
					right: '-400px'
				})
			),
			state(
				'open',
				style({
					right: '40px'
				})
			),
			transition('open <=> closed', [animate('0.5s ease-in-out')])
		])
	]
})
export class ToastComponent {
	@ViewChild('element', { static: false }) progressBar!: ElementRef;

	// Toast subscription
	// @autor: Carlos Sánchez Mora
	// @date: 29-07-2022
	constructor(public toastService: ToastService) {
		this.toastService.open.subscribe((data) => {
			if (data.show) {
				this.countDown();
			}
		});
	}

	// Countdown to close the toast
	// @autor: Carlos Sánchez Mora
	// @date: 29-07-2022
	countDown(): void {
		setTimeout(() => {
			this.toastService.hide();
		}, this.toastService.data.timeout);
	}

	// Close the toast
	// @autor: Carlos Sánchez Mora
	// @date: 29-07-2022
	close(): void {
		this.toastService.hide();
		return;
	}
}
